import React from "react";
import { useDispatch } from "react-redux";
import { handleModalInputValue } from "../../store/appSlice";
import "./Dropdown.css";

const Dropdown = ({ label, values, id }) => {
  const dispatch = useDispatch();

  return (
    <div className="Dropdown">
      <select
        name={label}
        id={label}
        onChange={(e) =>
          dispatch(handleModalInputValue({ value: e.target.value, id }))
        }
      >
        {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
