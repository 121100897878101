import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import InputDate from "../InputDate/InputDate";
import InputSearch from "../InputSearch/InputSearch";
import InputSearch2 from "../InputSearch/InputSearch2";
import InputText from "../InputText/InputText";
import InputTextarea from "../InputTextarea/InputTextarea";
import "./Input.css";

const Input = (props) => {
  const { label, type, isMandatory, size } = props;

  const renderInput = (type) => {
    switch (type) {
      case "text":
        return <InputText {...props} />;
      case "textarea":
        return <InputTextarea {...props} />;
      case "search":
        return <InputSearch {...props} />;
      case "search2":
        return <InputSearch2 {...props} />;
      case "date":
        return <InputDate {...props} />;
      case "dropdown":
        return <Dropdown {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className="Input">
      <label className={size}>
        {isMandatory && <span className="isMandatory-star">*</span>}
        {label && (
          <>
            {label}
            <br />
          </>
        )}
        {renderInput(type)}
      </label>
    </div>
  );
};

export default Input;
