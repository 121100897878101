import { useEffect } from "react";

// This hook allows us to detect a click outside of a component and/or the escape key pressed
const useUnfocus = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current?.contains(e.target)) {
      callback();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      // callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  });
};

export default useUnfocus;
