import React from "react";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectWS,
  loadInitialData,
  populatePageInputValues,
  setPageInputs,
  setSurgeriesTabs,
} from "../../store/appSlice";
import { getPageInputs } from "../../content/elements/NewSurgery/pageInputs";
import MobileApp from "../../components/MobileApp/MobileApp";
import "./PrivateLayout.css";
import { isMobile } from "../../utils/core";

const PrivateLayout = () => {
  const isLogged = useSelector((state) => state.app.isLogged);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const content = useSelector((state) => state.app.content);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(connectWS());
  }, [dispatch]);

  // TODO: what to do when user refreshes browser: get all user data
  useEffect(() => {
    if (isLogged) {
      dispatch(setSurgeriesTabs(content));
      if (isSaveToRefresh) {
        dispatch(loadInitialData()).then(() => {
          dispatch(setPageInputs(getPageInputs(content)));
          dispatch(populatePageInputValues());
        });
      }
    }
  }, [dispatch, content, isSaveToRefresh, isLogged]);

  if (isLogged && isMobile()) {
    return <MobileApp />;
  }

  return isLogged ? (
    <div className="PrivateLayout">
      <Topbar />
      <Sidebar />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateLayout;
