import HeaderBodega from "./HeaderBodega";
import SearchBar from "../../components/ReactInventory/SearchBar";

const HeaderBodegas = ({
  handleFilter,
  searchTerm,
  setSearchTerm,
  clientes,
  clients,
  getClients,
  product,
}) => {
  return (
    <div className="flex flex-col items-center">
      <HeaderBodega
        clientes={clientes}
        clients={clients}
        getClients={getClients}
        product={product}
      />
      <SearchBar
        handleFilter={handleFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  );
};

export default HeaderBodegas;
