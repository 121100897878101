import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addBodegaApi } from "../../../services/bodegas";
import { addBodega } from "../../../store/appSlice";
import Button from "../../Button/Button";
import DropdownSearch from "../../DropdownSearch/DropdownSearch";
import Input from "../../Input/Input";
import "./SelectBodega.css";

const SelectBodega = ({ onSelect, bodegas, clients, onCancel }) => {
  const [input, setInput] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const [newBodega, setNewBodega] = useState("");
  const [newBodegaCiudad, setNewBodegaCiudad] = useState("");
  const [newClient, setNewClient] = useState("");
  const [canCreate, setCanCreate] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="SelectBodega">
      <Input
        type="search2" // FIXME: MOVE TO SEARCH WHEN IMPROVED VERSION HAS BEEN REFACTORED
        canCreate
        size="cover"
        value={input}
        isFocused={inputFocus}
        placeholder="Buscar bodegas"
        suggestions={bodegas.filter(
          (suggestion) =>
            suggestion?.name.toLowerCase().includes(input.toLowerCase()) ||
            suggestion?.client.nombre
              .toLowerCase()
              .includes(input.toLowerCase()) ||
            suggestion?.client.nit.includes(input.toLowerCase())
        )}
        name="name"
        propertyObject="client"
        property="nombre"
        property2="nit"
        selectSuggestion={(item) => {
          onSelect(item, item.client.nombre + " - " + item.client.nit);
          setInput("");
          setCanCreate(false);
          setInputFocus(false);
        }}
        onCreate={() => {
          setInputFocus(false);
          setCanCreate(true);
          setNewBodega(input);
        }}
        onInputFocus={setInputFocus}
        handleChange={(e) => {
          setInput(e.target.value);
        }}
      />
      <div className="recommended">
        {bodegas &&
          bodegas.slice(0, 3).map((bodega) => (
            <div
              key={bodega._id}
              onClick={() => {
                onSelect(
                  bodega,
                  bodega.client.nombre + " - " + bodega.client.nit
                );
                setInput("");
                setCanCreate(false);
              }}
            >
              <p>{bodega.name}</p>
              <span>
                {bodega.client.nombre} - {bodega.client.nit}
              </span>
            </div>
          ))}
      </div>
      {canCreate && (
        <div className="create-bodega">
          <hr />
          <h2 className="modal-container-heading">Crear bodega</h2>
          <Input
            type="text"
            value={newBodega}
            label="Nombre"
            isMandatory
            handleChange={(e) => setNewBodega(e.target.value)}
          />
          <Input
            type="text"
            value={newBodegaCiudad}
            label="Ciudad"
            isMandatory
            handleChange={(e) => setNewBodegaCiudad(e.target.value)}
          />
          <DropdownSearch
            label="Cliente"
            findField="nombre"
            findField2="nit"
            prependField2="NIT."
            placeholder="Selecciona el cliente"
            suggestions={clients}
            isMandatory
            isRelative
            isFullWidth
            onSelect={(e, client) => {
              setNewClient(client);
            }}
          />
          <div className="form-footer">
            <Button label="Cancelar" variant="cancel" click={onCancel} />
            <Button
              label="Crear"
              disabled={
                newBodega === "" || newBodegaCiudad === "" || newClient === ""
              }
              click={async () => {
                const addedBodega = await addBodegaApi({
                  name: newBodega,
                  city: newBodegaCiudad,
                  client: newClient._id,
                });
                setCanCreate(false);
                onSelect(
                  addedBodega,
                  `${newClient.nombre} - ${newClient.info}`
                );
                dispatch(addBodega(addedBodega));
                setNewBodega("");
                setNewBodegaCiudad("");
                setNewClient("");
                setInput("");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectBodega;
