export const getFilterStatus = {
  quoteNumber: {
    pending: false,
    complete: false,
  },
  isDispatched: {
    pending: false,
    complete: false,
  },
  isSpent: {
    pending: false,
    complete: false,
  },
};
export const getFilterOptions = [
  {
    id: 1,
    section: "Cotización",
    db_identifier: "quoteNumber",
    pending: {
      name: "Pendiente",
      isChecked: false,
    },
    complete: {
      name: "Enviada",
      isChecked: false,
    },
  },
  {
    id: 2,
    section: "Despacho",
    db_identifier: "isDispatched",
    pending: {
      name: "Pendiente",
      isChecked: false,
    },
    complete: {
      name: "Enviado",
      isChecked: false,
    },
  },
  {
    id: 3,
    section: "Gasto",
    db_identifier: "isSpent",
    pending: {
      name: "Pendiente",
      isChecked: false,
    },
    complete: {
      name: "Recibido",
      isChecked: false,
    },
  },
];
