import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
import Confirm from "../../components/Modals/Confirm/Confirm";
import {
  deleteSurgeryGastoFile,
  uploadSingleFileApi,
} from "../../services/files";
import { FILE_TYPES } from "../../constants";
import { setBodegasAndLotes } from "../../store/appSlice";

const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];

const SpendSurgery = ({
  hasFileSpend,
  fileSpend,
  surgeryId,
  usedLotes,
  setSurgery,
  hasLoteInTransit,
  hasLoteToDispatch,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [gastoFile, setGastoFile] = useState(!!fileSpend ? fileSpend : "");
  const [localFile, setLocalFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(
    fileSpend ? "Archivo subido" : ""
  );
  const [confirmCopy, setConfirmCopy] = useState("");
  const [isOpenConfirm, setIsOpenConfirm] = useState();
  const [confirmCreateGasto, setConfirmCreateGasto] = useState(false);
  const [noLotesToSpend, setNoLotesToSpend] = useState(false);
  const [hasLotesInTransit, setHasLotesInTransit] = useState(false);
  const content = useSelector((state) => state.app.content);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const onButtonClick = () => {
    if (hasLoteInTransit || hasLoteToDispatch) {
      setHasLotesInTransit(true);
    } else if (!usedLotes.length) {
      setNoLotesToSpend(true);
    } else {
      // Trigger the file input element.
      fileInputRef.current.click();
    }
  };

  const onDragOver = (event) => {
    // Prevent the default behavior of the browser when a file is dragged over the drop zone.
    event.preventDefault();
    setIsDragging(true);
  };

  const handleUpload = (file) => {
    if (isFileSizeRigth(file.size)) {
      if (gastoFile === "") {
        setConfirmCreateGasto(true);
      } else {
        setIsOpenConfirm("upload");
        setConfirmCopy(
          `${content.texts.CONFIRM_REPLACE_FILE} ${gastoFile?.name}. Desea continuar?`
        );
      }
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    // Get the file that was dropped into the drop zone.
    const file = event.dataTransfer.files[0];
    setLocalFile(file);

    if (allowedTypes.includes(file.type)) {
      if (hasLoteInTransit || hasLoteToDispatch) {
        setHasLotesInTransit(true);
      } else if (!usedLotes.length) {
        setNoLotesToSpend(true);
      } else {
        handleUpload(file);
      }
    }
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setLocalFile(file);
    handleUpload(file);
  };

  const uploadFile = async (file) => {
    // Upload the selected file to your server or file storage service here.
    const formData = new FormData();
    formData.append("file", file);
    formData.append("surgeryId", surgeryId);
    formData.append("usedLotes", JSON.stringify(usedLotes));
    setUploadStatus("Subiendo archivo");
    const fileUploaded = await uploadSingleFileApi(FILE_TYPES.gastos, formData);
    setGastoFile(fileUploaded.data.file);
    setUploadStatus("Archivo subido");
    setSurgery((prevState) => ({ ...prevState, fileSpend: fileUploaded }));
    dispatch(setBodegasAndLotes(fileUploaded.data.updatedValues));
  };

  const deleteGastoFile = () => {
    setUploadStatus("Borrando archivo");
    deleteSurgeryGastoFile(surgeryId).then(() => {
      setUploadStatus("");
    });
  };

  const isFileSizeRigth = (fileSize) => {
    return fileSize < 5 * 1024 * 1024;
  };

  return (
    <div className="SpendSurgery">
      <div
        className={`drop-zone ${isDragging ? "drop-zone-dragging" : ""}`}
        onDragOver={!hasFileSpend ? onDragOver : undefined}
        onDrop={!hasFileSpend ? onDrop : undefined}
      >
        <p>
          Arrastra tu archivo aquí
          <br />{" "}
          <span
            className={`file-type ${
              !!localFile && !allowedTypes.includes(localFile?.type)
                ? "is-max"
                : ""
            }`}
          >
            (.png, .jpeg, .pdf)
          </span>
        </p>
        <p
          className={`max-file ${
            localFile?.size && !isFileSizeRigth(localFile.size) ? "is-max" : ""
          }`}
        >
          Tamaño máximo 5MB
        </p>
        <input
          type="file"
          className="input-upload"
          accept=".pdf,image/jpeg,image/png"
          ref={fileInputRef}
          onChange={(e) => {
            onFileChange(e);
            e.target.value = null;
          }}
        />
        <Button
          disabled={hasFileSpend}
          label="Buscar archivos"
          click={onButtonClick}
          size="xxsmall"
        />
      </div>
      <div className="uploaded-files">
        <p>{uploadStatus}</p>
        {(uploadStatus === "Subiendo archivo" ||
          uploadStatus === "Borrando archivo") && (
          <div className="loading-wrapper">
            <Loading />
          </div>
        )}
        {gastoFile && (
          <div className="uploaded-files-list">
            <span>
              <img
                src={`/assets/images/${gastoFile?.type.split("/")[1]}.png`}
                alt="asset type icon"
              />
              <p>{gastoFile?.name.toUpperCase()}</p>
              {!hasFileSpend && (
                <span
                  onClick={() => {
                    setIsOpenConfirm("delete");
                    setConfirmCopy(
                      `${content.texts.CONFIRM_DELETE_FILE} ${gastoFile?.name}?`
                    );
                  }}
                  className="delete-file"
                >
                  Borrar
                </span>
              )}
            </span>
            <a
              href={gastoFile.url}
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Ver archivo
            </a>
          </div>
        )}
      </div>
      <Modal isOpen={confirmCreateGasto}>
        <Confirm
          copy="Estás seguro de registrar este gasto?"
          note={{
            copy: "Esto eliminará lotes que hayas elegido en la sección Material",
            color: "#EE8D1C",
          }}
          close={() => setConfirmCreateGasto(false)}
          confirm={() => {
            uploadFile(localFile);
            setConfirmCreateGasto(false);
          }}
        />
      </Modal>
      <Modal isOpen={isOpenConfirm}>
        <Confirm
          copy={confirmCopy}
          close={() => setIsOpenConfirm("")}
          confirm={() => {
            setIsOpenConfirm("");
            if (isOpenConfirm === "delete") {
              setGastoFile("");
              deleteGastoFile();
            } else {
              setConfirmCreateGasto(true);
            }
          }}
        />
      </Modal>
      <Modal isOpen={noLotesToSpend}>
        <Confirm
          copy="No hay lotes para gastar"
          note={{
            copy: "Selecciona lotes antes de hacer un gasto",
            color: "#EE8D1C",
          }}
          close={() => setNoLotesToSpend(false)}
          closeLabel="Cerrar"
        />
      </Modal>
      <Modal isOpen={hasLotesInTransit}>
        <Confirm
          copy="No es posible gastar lote(s) en transito o por despachar"
          close={() => setHasLotesInTransit(false)}
          closeLabel="Cerrar"
        />
      </Modal>
    </div>
  );
};

export default SpendSurgery;
