export const convertToSuggestions = (values, name, name2, name1b) => {
  return values?.map((value) => ({
    id: value._id,
    name: name1b ? `${value[name]} ${value[name1b]}` : value[name],
    secondary_name: value[name2],
  }));
};

export const generateInputDashValue = (value, name, name2, name1b) => ({
  id: value._id,
  name: name1b ? `${value[name]} ${value[name1b]}` : value[name],
  secondary_name: value[name2],
});

export const addLeadingZeros = (number) => {
  return number.toString().padStart(3, "0");
};
