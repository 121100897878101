import React from "react";
import Container from "../../components/Container/Container";
import Page from "../../layout/Page/Page";

const NotFound = () => {
  return (
    <Page>
      <Container title="Oops!" subtitle="404">
        <p>Nothing found</p>
      </Container>
    </Page>
  );
};

export default NotFound;
