import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import HeaderBodegas from "../../components/ReactInventory/HeaderBodegas";
import DespachosMenu from "../../components/ReactInventory/DespachosMenu";
import DespachosRow from "../../components/ReactInventory/DespachosRow";
import Loading from "../../components/Loading/Loading";
import { useEffect, useState } from "react";
import { getDespachos } from "../../services/despachos";

function Shipments() {
  const [despachos, setDespachos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      getDespachos().then((ds) => {
        setIsLoading(false);
        const parsedDespachos = ds?.map((d) => ({
          ...d,
          status: d?.remisionFirmadaFile ? "Entregado" : "En transito",
        }));
        setDespachos(parsedDespachos);
        setFilteredData(parsedDespachos);
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleFilter = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const filtered = despachos.filter(
      (despacho) =>
        despacho.remisionNumber?.toString().includes(inputValue) ||
        despacho.status.toLowerCase().indexOf(inputValue) >= 0 ||
        despacho?.bodegaDestination.client.nombre
          .toLowerCase()
          .indexOf(inputValue) >= 0
    );
    setFilteredData(filtered);
    setSearchTerm(event.target.value);
  };

  const updateDespachos = (updatedDespachos) => {
    const parsedDespachos = updatedDespachos.map((d) => ({
      ...d,
      status: d?.remisionFirmadaFile ? "Entregado" : "En transito",
    }));
    setDespachos(parsedDespachos);
    const filtered = parsedDespachos.filter(
      (despacho) =>
        despacho.remisionNumber?.toString().includes(searchTerm) ||
        despacho.status.toLowerCase().indexOf(searchTerm) >= 0 ||
        despacho?.bodegaDestination.client.nombre
          .toLowerCase()
          .indexOf(searchTerm) >= 0
    );
    setFilteredData(filtered);
  };

  return (
    <Page>
      <Container mainTop={false}>
        <HeaderBodegas
          handleFilter={handleFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <div className="w-full">
          {isLoading ? (
            <Loading />
          ) : filteredData?.length ? (
            <>
              <DespachosMenu />
              {filteredData.map((despacho) => (
                <DespachosRow
                  key={despacho._id}
                  despacho={despacho}
                  updateDespachos={(val) => updateDespachos(val)}
                />
              ))}
            </>
          ) : (
            <p className="text-center">No hay despachos creados</p>
          )}
        </div>
      </Container>
    </Page>
  );
}

export default Shipments;
