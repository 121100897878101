const ItemRow = ({ product }) => {
  return (
    <div>
      <div className="flex justify-between items-center bg-cultured rounded-lg px-8 py-4 mb-4">
        <div>
          <h2 className="text-lg font-semibold">{product.nombre}</h2>
          <p className="text-xs font-light">{product.codigo}</p>
        </div>
        <div className="flex justify-end items-center">
          <div className="h-12 w-px bg-green-300 mr-8"></div>
          <div className="flex flex-col items-center w-16">
            <p className="text-xl font-semibold">{product.stock.length}</p>
            <p className="text-xs font-light">Unidades</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemRow;
