import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterSurgeries, setFilterStatus } from "../../store/appSlice";
import Checkbox from "../Checkbox/Checkbox";
import "./FilterColumn.css";

const FilterColumn = ({ name, dbId }) => {
  const content = useSelector((state) => state.app.content);
  const dispatch = useDispatch();
  const filterStatus = useSelector((state) => state.app.filterStatus);

  const handleSetFilterStatus = (status, isChecked) => {
    let newPending = false;
    let newComplete = false;

    if (status === "pending") {
      if (filterStatus[dbId].complete) {
        newPending = true;
        newComplete = false;
      } else {
        newPending = !filterStatus[dbId].pending;
        newComplete = filterStatus[dbId].newComplete;
      }
    } else {
      if (filterStatus[dbId].pending) {
        newPending = false;
        newComplete = true;
      } else {
        newComplete = !filterStatus[dbId].complete;
        newPending = filterStatus[dbId].pending;
      }
    }
    let updatedFilterStatus = {
      ...filterStatus,
      [dbId]: {
        pending: newPending,
        complete: newComplete,
      },
    };
    dispatch(setFilterStatus(updatedFilterStatus));
    dispatch(filterSurgeries({ filter: dbId, status, isChecked }));
  };

  return (
    <span className="FilterColumn">
      <span
        className={
          content.labels[name] === "Gasto" ? "filter-gasto" : "filter-title"
        }
      >
        {content.labels[name]}
        {name !== "SPEND" && (
          <span className="filter-checkboxes">
            <Checkbox
              variant="pending"
              checkState={filterStatus[dbId].pending}
              handleChange={() =>
                handleSetFilterStatus("pending", !filterStatus[dbId].pending)
              }
            />
            <Checkbox
              variant="complete"
              checkState={filterStatus[dbId].complete}
              handleChange={() =>
                handleSetFilterStatus("complete", !filterStatus[dbId].complete)
              }
            />
          </span>
        )}
      </span>
    </span>
  );
};

export default FilterColumn;
