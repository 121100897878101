import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import SearchSuggestion2 from "../SearchSuggestion/SearchSuggestion2";
// import useOutsideClick from "../../hooks/useUnfocus";
import "./InputSearch.css";

// IMPROVED VERSION OF InputSearch
const InputSearch2 = ({
  id,
  label,
  value,
  placeholder,
  suggestions,
  maxLength,
  canCreate,
  isSelected,
  isLast,
  isFocused,
  inputsParent,
  size,
  onCreate,
  handleChange,
  selectSuggestion,
  onInputFocus,
  name,
  propertyObject,
  property,
  property2,
}) => {
  const content = useSelector((state) => state.app.content);

  return (
    <div
      className={`InputSearch ${
        inputsParent === "modalInputs" && "modal-inputSearch"
      } ${size}`}
    >
      <input
        type="text"
        autoComplete="off"
        name={label}
        maxLength={maxLength}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={() => onInputFocus(true)}
        onBlur={() => onInputFocus(false)}
      />
      {value !== "" && canCreate && !isSelected && (
        <span className="create-in-input" onClick={onCreate}>
          {content.labels.CREATE} <FontAwesomeIcon icon="plus-circle" />
        </span>
      )}
      <SearchSuggestion2
        id={id}
        value={value}
        suggestions={suggestions}
        isFocused={isFocused}
        isSelected={isSelected}
        isLast={isLast}
        inputsParent={inputsParent}
        selectSuggestion={selectSuggestion}
        name={name}
        propertyObject={propertyObject}
        property={property}
        property2={property2}
      />
    </div>
  );
};

export default InputSearch2;
