import { url, axiosPrivate } from ".";

export const getDespachos = async () => {
  try {
    const despachos = await axiosPrivate.get(`${url}/despachos`);
    return despachos.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateDespachoApi = async (despacho, despachoId) => {
  try {
    const updatedDespacho = await axiosPrivate.put(
      `${url}/despachos/${despachoId}`,
      {
        despacho,
      }
    );
    return updatedDespacho.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const deleteDespachoApi = async (despachoId) => {
  try {
    const updatedDespachos = await axiosPrivate.delete(
      `${url}/despachos/${despachoId}`
    );
    return updatedDespachos.data.data;
  } catch (e) {
    console.log(e);
  }
};
