import React from "react";
import Container from "./Container";
import "./Modal.css";

const Modal = ({ isOpen, children }) => {
  return (
    <div className={`Modal ${isOpen ? "show-modal" : "hide-modal"}`}>
      <div className="modal-content">{children}</div>
    </div>
  );
};

Modal.Container = Container;
export default Modal;
