import Loading from "../Loading/Loading";

const Button = ({ variant, label, onClick, disabled, isLoading = false }) => {
  return (
    <button
      disabled={disabled}
      className={`w-[149px] h-[42px] rounded-[21px] font-medium btn-${variant} ${
        disabled ? "btn-disabled" : ""
      }`}
      onClick={onClick}
    >
      {isLoading ? (
        <span style={{ position: "relative" }}>
          <Loading />
        </span>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
