import React from "react";
import "./InputDate.css";

const InputDate = ({ label, value, disabled, handleChange }) => {
  return (
    <div className="InputDate">
      <input
        type="datetime-local"
        name={label}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};

export default InputDate;
