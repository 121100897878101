import { useLocation } from "react-router-dom";
import { FaWarehouse } from "react-icons/fa";

const HeaderBodega = ({ product }) => {
  let location = useLocation();

  const bodega = location.state?.bodega;
  const { name = "", client = "" } = bodega || {};
  const { nombre = "" } = client;

  return (
    <div className="w-3/4">
      {location.pathname === "/bodegas" && (
        <div className="grid grid-rows-3 w-full pb-8 justify-items-center items-center">
          <h1 className="text-3xl font-semibold">Bodegas</h1>
        </div>
      )}
      {location.pathname.includes("/lotes") && (
        <div className="grid grid-rows-3 w-full pb-8 justify-items-center items-center">
          <div className="flex">
            <h1 className="text-3xl font-semibold">{product.nombre}</h1>
            <div className="w-5 h-5 flex justify-center items-center rounded-full bg-green-300 ml-1">
              <p className="text-[10px]">{product.stock.length}</p>
            </div>
          </div>
          <p className="text-xl">{product.codigo}</p>
          <div className="flex">
            <FaWarehouse className="fill-raisin-black mr-2" />
            <p className="text-xs text-raisin-black">
              <span className="font-light">Bodega actual: </span>
              {name} - {nombre}
            </p>
          </div>
        </div>
      )}
      {location.pathname === "/despachos" && (
        <div className="grid grid-rows-1 w-full pb-8 justify-items-center items-center">
          <h1 className="text-3xl font-semibold">Despachos</h1>
        </div>
      )}
    </div>
  );
};

export default HeaderBodega;
