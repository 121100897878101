import { FaSearch, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { clearFilterQuery } from "../../store/appSlice";
import "./Searchbar.css";

const Searchbar = ({
  placeholder,
  // theme,
  handleChange,
  // leftIcon,
  searchTerm,
  clearSearch,
  isGlobal = true,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={`w-4/5 pb-10`}>
      <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
        <FaSearch className="fill-cool-grey mr-2" />
        <input
          className="w-full border-none outline-none placeholder:text-cool-grey"
          type="text"
          placeholder={placeholder}
          onKeyUp={handleChange}
          onChange={handleChange}
          value={searchTerm}
        />
        {searchTerm !== "" && (
          <span
            className="clear-input"
            onClick={() =>
              isGlobal ? dispatch(clearFilterQuery()) : clearSearch()
            }
          >
            <FaTimes className="cursor-pointer" />
          </span>
        )}
      </div>
    </div>
  );
};

Searchbar.defaultProps = {
  // theme: "",
  // leftIcon: true,
  handleChange: () => {},
};

export default Searchbar;
