import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Button from "../../Button/Button";
import "./Confirm.css";

const Confirm = ({
  copy,
  note,
  isLoading,
  confirmLabel,
  closeLabel,
  confirm,
  close,
}) => {
  const content = useSelector((state) => state.app.content);

  return (
    <div className="Confirm">
      <div className="confirm-close" onClick={() => close(false)}>
        <FontAwesomeIcon icon="times-circle" />
      </div>
      <div className="confirm-header">
        <div className="confirm-main">
          <p>{copy}</p>
          <p className="confirm-note" style={{ color: note?.color }}>
            {note?.copy}
          </p>
        </div>
      </div>
      <div className="confirm-content"></div>
      <div className="confirm-footer">
        <Button
          label={closeLabel || content.labels.CANCEL}
          variant="cancel"
          click={() => close()}
        />
        {confirm && (
          <Button
            label={confirmLabel || content.labels.CONFIRM}
            click={() => {
              confirm();
            }}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default Confirm;
