import { url, axiosPrivate } from ".";

export const uploadSingleFileApi = async (fileType, formData) => {
  try {
    const fileUploaded = await axiosPrivate.post(
      `${url}/files/${fileType}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return fileUploaded.data;
  } catch (e) {
    console.log(e);
  }
};

export const getSingleFileApi = async (fileType, surgeryId) => {
  try {
    const file = await axiosPrivate.post(`${url}/files/${fileType}/download`, {
      surgeryId,
    });
    return file.data;
  } catch (e) {
    console.log(e);
  }
};

export const deleteSurgeryGastoFile = async (surgeryId) => {
  try {
    const deletedFile = await axiosPrivate.delete(
      `${url}/files/gasto/${surgeryId}`
    );
    return deletedFile.data;
  } catch (e) {
    console.log(e);
  }
};

export const createFileApi = async (fileType, despacho) => {
  try {
    const newDespacho = await axiosPrivate.post(
      `${url}/files/${fileType}/create`,
      {
        ...despacho,
      }
    );
    return newDespacho.data.data;
  } catch (e) {
    console.log(e);
  }
};
