import moment from "moment";
import LoadingDots from "../../components/Loading/LoadingDots";

const MaterialCard = ({
  material,
  hasFileSpend,
  onSelect,
  onDelete,
  onSwap,
  isLoading,
  selectedMaterial,
}) => {
  const materialLength = material.lotes?.length;

  return (
    <div className="MaterialCard">
      <div className="material-name">
        <p>{material.producto.nombre}</p>
        <div>
          <span
            className={`material-qty ${
              materialLength >= material.quantity ? "material-linked" : ""
            }`}
          >
            {material?.lotes?.length || "0"}/{material.quantity}
          </span>
        </div>
      </div>
      <div className="material-card-info">
        <p className="material-card-item">Lote</p>
        <div className="material-card-expiry-date">
          <p className="material-card-item">Fecha de vencimiento</p>
        </div>
      </div>
      {materialLength > 0 &&
        material.lotes.map((lote) => (
          <div key={lote._id} className="material-card-selected">
            <p>
              {lote.lote}{" "}
              {lote.inTransit && (
                <span className="text-carrot-orange">En transito</span>
              )}
              {lote.needToBeDispatched && !hasFileSpend && (
                <span className="text-red-500">Despachar</span>
              )}
            </p>
            <p className="material-card-expiry">
              {moment(lote.expiryDate).format("DD/MM/YYYY")}
            </p>
            <div className="material-card-actions mr-6">
              <p className="change-material" onClick={() => onSwap(lote)}>
                {lote.lote && !hasFileSpend && "Cambiar"}
              </p>
              <p className="change-material" onClick={() => onDelete(lote)}>
                {lote.lote && !hasFileSpend && "Borrar"}
              </p>
            </div>
          </div>
        ))}
      {(!material.lotes || materialLength < material.quantity) && (
        <div className="material-card-selected">
          <p>-</p>
          <p>-</p>
          <p></p>
        </div>
      )}
      {!(materialLength >= material.quantity) && !hasFileSpend && (
        <span className="link" onClick={onSelect}>
          Seleccionar lotes
        </span>
      )}
      {isLoading &&
        selectedMaterial.producto?._id === material.producto?._id && (
          <LoadingDots style={{ justifyContent: "flex-end", height: 0 }} />
        )}
    </div>
  );
};

export default MaterialCard;
