import React from "react";
import "./Tooltip.css";

const Tooltip = ({ title, subtitle, items }) => {
  return (
    <div className="Tooltip">
      <div className="tooltip-header">
        <p className="tooltip-title">{title}</p>
        <p className="tooltip-subtitle">{subtitle}</p>
      </div>
      <div className="tooltip-main">
        {items?.map((item) => (
          <div className="tooltip-item" key={item.id}>
            <p>{item.name}</p>
            <p>{item.quantity}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tooltip;
