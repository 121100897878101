import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  searchFilterSurgeries,
  clearSurgeriesFilters,
} from "../../store/appSlice";
import Searchbar from "../Searchbar/Searchbar";
import "./Filter.css";

const Filter = () => {
  const selectedFilters = useSelector((state) => state.app.selectedFilters);
  const filterQuery = useSelector((state) => state.app.filterQuery);
  const isShowsMaterials = useSelector((state) => state.app.isShowsMaterials);
  const dispatch = useDispatch();

  return (
    <div className="Filter">
      {(selectedFilters.length > 0 ||
        filterQuery !== "" ||
        isShowsMaterials) && (
        <span
          className="clean-filters"
          onClick={() => dispatch(clearSurgeriesFilters())}
        >
          Limpiar filtros
        </span>
      )}
      <div className="filter-searchbar">
        <Searchbar
          placeholder="Filtrar paciente, clínica, ciudad o factura"
          handleChange={(e) => dispatch(searchFilterSurgeries(e))}
          leftIcon
          searchTerm={filterQuery}
        />
      </div>
    </div>
  );
};

export default Filter;
