export const getPageInputs = (content) => {
  return [
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: true,
      identifier: "paciente",
      parsedIdentifier: "patient",
      db_identifier: "patients",
      entity_id: 0,
      name: "Patient",
      label: content.labels.PATIENT,
      value: "",
      type: "search",
      suggestion1: "nombres",
      suggestion1b: "apellidos",
      suggestion2: "numero_id",
      canCreate: true,
      isFocused: false,
      isSelected: false,
      isMandatory: true,
      inputsParent: "pageInputs",
      values: [],
    },
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: true,
      identifier: "cliente",
      parsedIdentifier: "client",
      db_identifier: "clients", // FIXME: improve the naming on identifiers
      entity_id: 0,
      name: "Client",
      label: content.labels.CLIENT,
      value: "",
      type: "search",
      inputsParent: "pageInputs",
      suggestion1: "nombre",
      suggestion2: "nit",
      canCreate: true,
      isFocused: false,
      isMandatory: true,
      isSelected: false,
      values: [],
    },
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: true,
      identifier: "clinica",
      parsedIdentifier: "clinic",
      db_identifier: "clinics",
      entity_id: 0,
      name: "Clinic",
      label: content.labels.CLINIC,
      value: "",
      // TODO: improve field settings
      canSearch: {
        db: "clinics",
        suggestion1: "nombre",
        suggestion2: "ciudad",
        inputsParent: "pageInputs",
        canCreate: true,
      },
      type: "search",
      inputsParent: "pageInputs",
      suggestion1: "nombre",
      suggestion2: "ciudad",
      canCreate: true,
      isFocused: false,
      isSelected: false,
      values: [],
    },
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: true,
      identifier: "especialista",
      parsedIdentifier: "specialist",
      db_identifier: "specialists",
      entity_id: 0,
      name: "Specialist",
      label: content.labels.SPECIALIST,
      value: "",
      type: "search",
      inputsParent: "pageInputs",
      suggestion1: "nombres",
      suggestion1b: "apellidos",
      suggestion2: "especialidad",
      canCreate: true,
      isFocused: false,
      isSelected: false,
      values: [],
    },
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: true,
      identifier: "fecha_cx",
      entity_id: 0,
      name: "Date",
      label: content.texts.SURGERY_DATE_TIME,
      value: "",
      type: "date",
      isSelected: false,
    },
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: true,
      identifier: "material_solicitado",
      entity_id: 0,
      // parsedIdentifier: "materials",
      db_identifier: "materials",
      name: "Material",
      label: content.labels.REQUESTED_MATERIAL,
      title: content.labels.PRODUCT,
      value: "",
      type: "search",
      inputsParent: "pageInputs",
      suggestion1: "nombre",
      suggestion2: "codigo",
      isFocused: false,
      isSelected: false,
      canCreate: true,
      canIncrement: true,
      selectedValues: [],
      values: [],
      // isLast: true,
    },
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: false,
      identifier: "notes",
      entity_id: 0,
      db_identifier: "notes",
      name: "Notes",
      label: content.labels.NOTES,
      value: "",
      type: "textarea",
      inputsParent: "pageInputs",
      isFocused: false,
      isSelected: false,
    },
  ];
};

export const IMPROVED_VERSION__getPageInputs = (content) => {
  return [
    {
      id: Math.random().toString(16).slice(2),
      hasSummary: true,
      identifier: "paciente",
      parsedIdentifier: "patient",
      db_identifier: "patients",
      entity_id: 0,
      name: "Patient",
      label: content.labels.PATIENT,
      value: "",
      type: "search",
      suggestion1: "nombres",
      suggestion1b: "apellidos",
      suggestion2: "numero_id",
      canCreate: true,
      isFocused: false,
      isSelected: false,
      isMandatory: true,
      inputsParent: "pageInputs",

      canSearch: {
        db: "clinics",
        suggestion1: "nombre",
        suggestion2: "ciudad",
        inputsParent: "pageInputs",
        canCreate: true,
      },

      values: [],
      suggestions: [],
    },
  ];
};
