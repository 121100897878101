import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import "./Sidebar.css";

const Sidebar = () => {
  return (
    <div className="Sidebar">
      <Navigation />
    </div>
  );
};

export default Sidebar;
