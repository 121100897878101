import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearchSuggestion,
  updateMaterialQuantity,
} from "../../store/appSlice";
import "./SearchSuggestion.css";

const SearchSuggestion = ({
  id,
  value,
  isFocused,
  isSelected,
  isLast,
  canIncrement,
  inputsParent,
}) => {
  const suggestions = useSelector((state) => state.app.suggestions);
  const dispatch = useDispatch();

  return (
    <div className="SearchSuggestion">
      <div
        className={`suggestion-container ${
          inputsParent === "modalInputs" && "suggestion-container-modal"
        } ${
          suggestions.length > 0 && value !== "" && !isSelected && isFocused
            ? "show-suggestions"
            : ""
        }`}
        style={{ position: isLast ? "relative" : "absolute" }}
      >
        {suggestions.map((item, index) => (
          <p
            key={item.id}
            className="suggestion"
            onClick={() => {
              if (canIncrement) {
                dispatch(
                  updateMaterialQuantity({
                    id,
                    item,
                    index,
                    action: "increment",
                  })
                );
              } else {
                dispatch(
                  selectSearchSuggestion({ item, id, inputs: inputsParent })
                );
              }
            }}
          >
            {item.value}
          </p>
        ))}
      </div>
    </div>
  );
};

export default SearchSuggestion;
