import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTruck, faClinicMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Input from "../../Input/Input";
import LoteCard from "../../LoteCard/LoteCard";

const Despacho = ({
  formEntries,
  setFormEntries,
  isNewDespacho,
  despachoSelected,
  setDespachoLotes,
  selectedLotes,
  client,
}) => {
  return (
    <div className="Despacho">
      <div className="despacho-form">
        <div className="despacho-form-left">
          {formEntries &&
            formEntries.map((item) => (
              <Input
                {...item}
                key={item.id}
                value={item.value}
                disabled={!isNewDespacho && item.isMandatory}
                handleChange={(e) => {
                  setFormEntries((prevFormEntries) =>
                    prevFormEntries.map((entry) =>
                      entry.identifier === item.identifier
                        ? { ...entry, value: e.target.value }
                        : entry
                    )
                  );
                }}
              />
            ))}
        </div>
        <div className="despacho-form-right">
          <p className="subheading">
            {isNewDespacho ? "Seleccionar lotes" : "Lotes"}
          </p>
          <div className="text-xs">
            <FontAwesomeIcon icon={faTruck} color="rgb(100 116 139)" />
            <span className="text-slate-500"> - En transito</span>
          </div>
          <div className="text-xs mb-4">
            <FontAwesomeIcon icon={faClinicMedical} color="rgb(100 116 139)" />
            <span className="text-slate-500">
              {" "}
              - Ya esta en bodega del cliente
            </span>
          </div>
          <div className="despacho-lotes">
            {isNewDespacho && selectedLotes.every((dl) => dl.inTransit) && (
              <div className="placeholder-wrapper">
                <FontAwesomeIcon icon={faPenToSquare} color="grey" />
                <p className="placeholder">
                  No hay lotes seleccionados. <br />
                  Selecciona lotes en la seccion de material
                </p>
              </div>
            )}
            {!isNewDespacho
              ? despachoSelected.lotes.map((l) => (
                  <LoteCard key={l._id} despachoLote={l} />
                ))
              : selectedLotes
                  .filter((sl) => !sl.inTransit && sl.clientId !== client?._id)
                  .map((sl) => (
                    <LoteCard
                      key={sl._id}
                      despachoLote={sl}
                      alreadyIn={sl.clientId === client?._id}
                      updateDespachoLotes={(lote) => {
                        setDespachoLotes(
                          selectedLotes.map((l) =>
                            l._id === lote._id
                              ? { ...l, selected: !l.selected }
                              : l
                          )
                        );
                      }}
                    />
                  ))}
            {isNewDespacho &&
              selectedLotes
                .filter((dl) => dl.inTransit || dl.clientId === client?._id)
                .map((dl) => (
                  <LoteCard
                    key={dl._id}
                    despachoLote={dl}
                    alreadyIn={dl.clientId === client?._id}
                  />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Despacho;
