import { faEye, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const DeliverMaterial = ({ despacho, hasFileSpend, editDelivery }) => {
  const courierLogos = ["servientrega"];
  return (
    <div className="DeliverMaterial">
      <div className="dispatcher">
        {despacho.courier && courierLogos.includes(despacho.courier) ? (
          <img
            src={`/assets/images/${despacho.courier}.png`}
            alt="Delivery icon"
          />
        ) : (
          <span className="delivery-img-place"></span>
        )}
        <div>
          <p>{despacho.courier || "-"}</p>
          <p className="label-small">
            {despacho.date && moment(despacho.date).format("DD MMMM YYYY")}
          </p>
        </div>
      </div>
      <div className="reference">
        <p className="label-tiny">No. guia</p>
        <p className="reference-number">{despacho.guideNumber || "-"}</p>
      </div>
      <div className="delivery-route">
        <div className="location-graph">
          <div className="location-point location-from">
            <span></span>
          </div>
          <div className="location-line"></div>
          <div className="location-point location-to">
            <span></span>
          </div>
        </div>
        <div className="locations">
          <p>{despacho.origin}</p>
          <p>{despacho.destination}</p>
        </div>
      </div>
      <div className="deliver-material-icons">
        {!hasFileSpend && (
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => editDelivery(despacho)}
          />
        )}
        <a href={despacho?.remisionFile?.url} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faEye} />
        </a>
      </div>
    </div>
  );
};

export default DeliverMaterial;
