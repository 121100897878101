import { useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ handleFilter, searchTerm, setSearchTerm }) => {
  let location = useLocation();

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    handleFilter(event);
  };

  return (
    <div
      className={`${
        location.pathname.includes("/despacho/") ? "w-3/4" : "w-3/4"
      } pb-10`}
    >
      {location.pathname === "/bodegas" && (
        <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
          <FaSearch className="fill-cool-grey mr-2" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleChange}
            className="w-full border-none outline-none placeholder:text-cool-grey"
            placeholder="Busca una bodega o cliente"
          />
        </div>
      )}
      {location.pathname.includes("/lotes") && (
        <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
          <FaSearch className="fill-cool-grey mr-2" />
          <input
            className="w-full border-none outline-none placeholder:text-cool-grey"
            value={searchTerm}
            onChange={handleChange}
            type="text"
            placeholder="Busca por lote o fecha de vencimiento"
          />
        </div>
      )}
      {location.pathname.includes("/despacho/") && (
        <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
          <FaSearch className="fill-cool-grey mr-2" />
          <input
            className="w-full border-none outline-none placeholder:text-cool-grey"
            type="text"
            placeholder="Busca por lote, nombre de producto o código"
          />
        </div>
      )}
      {location.pathname === "/despachos" && (
        <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
          <FaSearch className="fill-cool-grey mr-2" />
          <input
            className="w-full border-none outline-none placeholder:text-cool-grey"
            type="text"
            value={searchTerm}
            onChange={handleChange}
            placeholder="Busca por remisión, cliente o estado"
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
