import React from "react";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faTruck, faClinicMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "./LoteCard.css";

const LoteCard = ({ despachoLote, updateDespachoLotes, alreadyIn }) => {
  return (
    <div
      className={`LoteCard ${
        (despachoLote.inTransit || alreadyIn) && "has-despacho"
      }`}
    >
      <div className="card-row-main">
        <div>
          <p className="row-main-lote">{despachoLote?.lote}</p>
          <p className="row-main-info">
            {despachoLote?.product?.nombre} - {despachoLote?.product?.codigo}
          </p>
        </div>
        <p className="row-date">
          {moment(despachoLote?.expiryDate).format("DD/MM/YYYY")}
        </p>
      </div>
      <div className="line-vertical"></div>
      <div
        className="row-icon"
        onClick={() => {
          updateDespachoLotes &&
            !despachoLote.inTransit &&
            updateDespachoLotes(despachoLote);
        }}
      >
        {despachoLote.selected || !updateDespachoLotes ? (
          <FontAwesomeIcon
            icon={
              !despachoLote.inTransit && alreadyIn
                ? faClinicMedical
                : despachoLote.inTransit || !updateDespachoLotes
                ? faTruck
                : faCircleCheck
            }
          />
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};

export default LoteCard;
