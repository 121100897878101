import React from "react";
import { useDispatch } from "react-redux";
import { updateMaterialQuantity } from "../../store/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Incrementor.css";

const Incrementor = ({ selectedValues, id, item, index }) => {
  const dispatch = useDispatch();

  return (
    <div className="Incrementor">
      <span className="quantity-counter">
        <span
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              updateMaterialQuantity({
                id,
                item,
                index,
                action: "decrement",
              })
            );
          }}
          className="quantity-minus"
        >
          <FontAwesomeIcon icon="minus-circle" />
        </span>
        <span className="quantity-value">
          {
            selectedValues.find((material) => material.value === item?.value)
              .quantity
          }
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              updateMaterialQuantity({
                id,
                item,
                index,
                action: "increment",
              })
            );
          }}
          className="quantity-plus"
        >
          <FontAwesomeIcon icon="plus-circle" />
        </span>
      </span>
    </div>
  );
};

export default Incrementor;
