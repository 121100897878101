const ES_Content = {
  status: {
    TRANSIT: "En transito 3",
    SURGERY: "No. cirugías pendientes",
  },
  button: {
    CREATE_BODEGA: "Crear bodega",
    CREATE_DISPATCH: "Crear despacho",
    CREATE_INGRESO: "Crear ingreso",
    CREATE_SURGERY: "Crear cirugía",
    SAVE_SURGERY: "Guardar cirugía",
    SAVE_CHANGES: "Guardar cambios",
  },
  placeholder: {
    SEARCH: "Buscar cirugías, clientes, clínicas y más...",
    FILTER: "Filtrar pacientes, clínicas y más...",
  },
  heading: {
    SETTINGS_PAGE_TITLE: "Ajustes",
    SETTINGS_PAGE_SUBTITLE: "Configuraciones de la aplicación",
    SURGERIES_PAGE_SUBTITLE: "Información sobre todos los registros",
  },
  texts: {
    CLIENT_HELP_TEXT: "Selecciona el cliente para asociar con esta clínica",
    CONFIRM_DELETE_FILE: "¿Estás seguro de que deseas eliminar el archivo",
    CONFIRM_DELETE_QUOTE:
      "¿Está seguro de que desea eliminar este número de factura?",
    CONFIRM_DELETE_SURGERY:
      "¿Estás seguro de que quieres eliminar esta cirugía?",
    CONFIRM_REPLACE_FILE: "Esta accion remplazara el archivo",
    COMING_SURGERIES: "Próximas cirugías",
    EDIT_SURGERY: "Editar cirugía",
    LAST_UPDATED: "Ult. modificación",
    NEW_SURGERY: "Nueva cirugía",
    NEW_SURGERY_SUMMARY: "Resumen de la nueva cirugía",
    NO_SURGERIES: "No hay cirugías registradas",
    PAST_SURGERIES: "Cirugías pasadas",
    CLOSED_SURGERIES: "Cirugías cerradas",
    PRESS_ENTER: "presiona ENTER",
    SEARCH_OR_CREATE: "Busca o crea a través de los campos de texto",
    SELECT_LANGUAGE: "Selecciona el idioma",
    SURGERY_DATE_TIME: "Fecha y hora de cirugía",
    SURGERIES_NODATE: "Cirugías sin fecha",
    SURGERIES_NOSPEND: "Cirugías sin gasto",
    TO_DISPATCH: "Por despachar",
    TO_QUOTE: "Por facturar",
    TO_SPEND: "Por gasto",
  },
  labels: {
    ADDRESS: "Dirección",
    BODEGA: "Bodega",
    CANCEL: "Cancelar",
    CITY: "Ciudad",
    CLIENT: "Cliente",
    CLIENTS: "Clientes",
    CLINIC: "Clínica",
    CODE: "Código",
    CONFIRM: "Confirmar",
    CREATE: "Crear",
    CREATED: "Creada",
    DATE: "Fecha",
    DELIVERY: "Despacho",
    DELIVERED: "Despachado",
    EMAIL: "Correo electrónico",
    ENTER_LOTES: "Ingresos",
    ENTITIES: "Entidades",
    FILTER: "Filtrar",
    HOME: "Inicio",
    ID_NUMBER: "Número ID",
    ID_TYPE: "Tipo ID",
    IN_TRANSIT: "En transito",
    LAST_NAMES: "Apellidos",
    LOGIN: "Iniciar sesión",
    LOGOUT: "Salir",
    LOTE: "Lote",
    MATERIAL: "Material",
    NAME: "Nombre",
    NAMES: "Nombres",
    NOTES: "Notas",
    NUMBER: "Número",
    OFFICE_ADDRESS: "Dirección consultorio",
    PATIENT: "Paciente",
    PASSWORD: "Contraseña",
    PENDING: "Pendiente",
    PRODUCT: "Producto",
    QUOTE: "Factura",
    RECEIVED: "Recibido",
    REQUESTED_MATERIAL: "Material solicitado",
    SEARCH: "Buscar",
    SETTINGS: "Ajustes",
    SHIPMENTS: "Despachos",
    SPECIALIST: "Especialista",
    SPECIALITY: "Especialidad",
    SPEND: "Gasto",
    SUMMARY: "Resumen",
    SURGERY: "Cirugía",
    SURGERIES: "Cirugías",
    TELEPHONE: "Teléfono",
    VIEW: "Ver",
    WAREHOUSES: "Bodegas",
  },
};

export default ES_Content;
