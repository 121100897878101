import React from "react";
import "./Logo.css";

const Logo = ({ size, color }) => {
  return (
    <svg
      className={`Logo logo-${size}`}
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511 118.77"
      fill={color}
    >
      <title>Logo Vive Solutions</title>
      <g>
        <path d="m54.93,64.98h10.45l15.08,42.8h.61l15-42.8h10.53l-19.47,53.03h-12.73l-19.47-53.03Z" style={{fill: '#00c0c0'}}/>
        <path d="m115.61,62.18c2.88,0,5.45,2.35,5.45,5.45s-2.58,5.45-5.45,5.45-5.53-2.35-5.53-5.45,2.58-5.45,5.53-5.45Zm-4.85,16.44h9.7v39.39h-9.7v-39.39Z" style={{fill: '#00c0c0'}}/>
        <path d="m123.87,78.62h9.85l10.61,30.15h.38l10.61-30.15h9.92l-14.62,39.39h-12.19l-14.54-39.39Z" style={{fill: '#00c0c0'}}/>
        <path d="m164.48,98.24c0-14.54,9.01-20.38,19.32-20.38s18.49,5.61,18.49,20c0,1.36-.08,2.12-.23,2.95h-28.41c.61,7.35,4.32,10.53,10.53,10.53,4.92,0,7.8-1.89,8.94-5.68h8.64c-2.12,8.94-9.02,13.11-17.8,13.11-10.23,0-19.47-5.83-19.47-20.53Zm28.63-3.33c-.23-5.53-3.11-9.62-9.47-9.62-5.68,0-9.24,3.18-9.92,9.62h19.39Z" style={{fill: '#00c0c0'}}/>
        <path d="m221.76,102.56h6.29c.38,6.06,4.55,10.83,14.09,10.83,8.71,0,13.03-4.02,13.03-10s-4.24-8.33-10.45-9.54l-7.05-1.44c-9.24-1.82-14.39-6.29-14.39-13.94,0-8.64,6.51-14.17,18.41-14.17s18.41,5.53,18.41,14.17h-6.21c-.23-5.08-4.17-8.86-12.27-8.86s-11.82,3.94-11.82,8.86c0,4.32,2.65,7.5,9.62,8.86l6.82,1.36c9.7,1.97,15.45,5.98,15.45,14.62,0,9.85-7.5,15.45-19.62,15.45-13.33,0-20.3-6.74-20.3-16.21Z" style={{fill: '#9a9c9b'}}/>
        <path d="m265.77,98.32c0-14.01,9.17-20.45,18.94-20.45s18.94,6.44,18.94,20.45-9.17,20.45-18.94,20.45-18.94-6.44-18.94-20.45Zm18.94,15.3c6.82,0,13.1-4.7,13.1-15.3s-6.29-15.38-13.1-15.38-13.18,4.77-13.18,15.38,6.29,15.3,13.18,15.3Z" style={{fill: '#9a9c9b'}}/>
        <path d="m308.8,64.98h6.06v53.03h-6.06v-53.03Z" style={{fill: '#9a9c9b'}}/>
        <path d="m338.04,113.62c6.59,0,11.14-3.94,11.59-10.76l.08-24.24h6.06l-.08,39.39h-6.06v-7.73h-.15c-.68,2.65-4.47,8.49-13.56,8.49-7.8,0-14.09-4.24-14.09-15.83l.08-24.32h6.06l-.08,23.11c0,7.5,3.18,11.89,10.15,11.89Z" style={{fill: '#9a9c9b'}}/>
        <path d="m366.61,108.77v-25.23h-6.36v-4.92h6.36v-9.85h6.06v9.85h7.8v4.92h-7.8v24.09c0,4.24,1.44,5.91,5.68,5.91.99,0,1.67,0,2.73-.15v4.7c-1.36.3-2.8.45-4.39.45-5.91,0-10.07-2.5-10.07-9.77Z" style={{fill: '#9a9c9b'}}/>
        <path d="m388.88,63.16c2.05,0,3.79,1.67,3.79,3.79s-1.74,3.86-3.79,3.86-3.86-1.74-3.86-3.86,1.82-3.79,3.86-3.79Zm-3.03,15.45h6.06v39.39h-6.06v-39.39Z" style={{fill: '#9a9c9b'}}/>
        <path d="m397.06,98.32c0-14.01,9.17-20.45,18.94-20.45s18.94,6.44,18.94,20.45-9.17,20.45-18.94,20.45-18.94-6.44-18.94-20.45Zm18.94,15.3c6.82,0,13.1-4.7,13.1-15.3s-6.29-15.38-13.1-15.38-13.18,4.77-13.18,15.38,6.29,15.3,13.18,15.3Z" style={{fill: '#9a9c9b'}}/>
        <path d="m457.74,83.01c-6.97,0-11.59,4.39-11.59,11.89v23.11h-6.06v-39.39h6.06v7.73h.15c.68-2.65,4.47-8.49,13.56-8.49,7.8,0,14.09,4.24,14.09,15.83v24.32h-6.06v-23.11c0-7.5-3.18-11.89-10.15-11.89Z" style={{fill: '#9a9c9b'}}/>
        <path d="m478.8,106.8h5.46c.76,5.38,5.68,7.2,10.83,7.2s9.7-1.97,9.7-6.89c0-3.86-2.65-5.15-6.89-6.14l-7.42-1.74c-6.97-1.67-10.45-5.23-10.45-10.61,0-6.97,5.91-10.76,14.85-10.76s14.7,3.79,15.15,11.67h-5.15c-.83-5.15-4.54-6.89-10-6.89-5.76,0-8.94,1.89-8.94,5.98,0,3.03,1.82,4.92,6.59,5.98l7.35,1.74c6.51,1.52,11.13,4.02,11.13,10.68,0,8.64-7.88,11.74-15.98,11.74-9.01,0-15.83-3.71-16.21-11.97Z" style={{fill: '#9a9c9b'}}/>
      </g>
      <g>
        <path d="m39.52,118.77H14.65c-.8,0-1.53-.42-1.92-1.11L.29,96.12c-.39-.69-.39-1.53,0-2.22l12.44-21.54c.39-.68,1.13-1.11,1.92-1.11h24.86c.79,0,1.52.42,1.92,1.11l12.43,21.54c.4.69.4,1.53,0,2.22l-12.43,21.53c-.4.69-1.13,1.11-1.92,1.11Zm-23.59-4.43h22.31l11.15-19.32-11.15-19.33H15.93l-11.15,19.33,11.15,19.32Z" style={{fill: '#d4d4d4'}}/>
        <path d="m39.52,71.25H14.65c-.79,0-1.52-.42-1.92-1.11L.3,48.61c-.4-.69-.4-1.53,0-2.22l12.43-21.53c.4-.69,1.13-1.11,1.92-1.11h24.87c.79,0,1.53.42,1.92,1.11l12.43,21.53c.4.68.4,1.53,0,2.22l-12.43,21.54c-.4.68-1.13,1.11-1.92,1.11Zm-23.59-4.43h22.31l11.15-19.32-11.15-19.32H15.93l-11.16,19.32,11.16,19.32Z" style={{fill: '#9a9c9b'}}/>
        <path d="m80.65,47.5h-24.87c-.79,0-1.52-.42-1.92-1.11l-12.43-21.54c-.4-.69-.4-1.53,0-2.22L53.86,1.11c.4-.69,1.13-1.11,1.92-1.11h24.87c.79,0,1.53.42,1.92,1.11l12.43,21.53c.4.68.4,1.53,0,2.22l-12.43,21.54c-.4.68-1.13,1.11-1.92,1.11Zm-23.59-4.43h22.31l11.15-19.32-11.15-19.32h-22.31l-11.16,19.32,11.16,19.32Z" style={{fill: '#00c0c0'}}/>
      </g>
    </svg>
  );
};

export default Logo;
