export const getTabs = (content) => {
  return [
    {
      id: 1,
      name: "soon",
      title: content.texts.COMING_SURGERIES,
      active: true,
    },
    {
      id: 2,
      // name: "nodate",
      name: "nogasto",
      title: content.texts.SURGERIES_NOSPEND,
      active: false,
    },
    {
      id: 3,
      // name: "past",
      name: "closed",
      title: content.texts.CLOSED_SURGERIES,
      active: false,
    },
  ];
};
