import { url, axiosPrivate } from ".";

export const getBodegasApi = async () => {
  try {
    const bodegas = await axiosPrivate.get(`${url}/bodegas`);
    return bodegas.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const addBodegaApi = async (bodega) => {
  try {
    const newBodega = await axiosPrivate.post(`${url}/bodegas`, {
      bodega,
    });
    return newBodega.data.data;
  } catch (e) {
    console.log(e);
  }
};
