import { FaFilePdf } from "react-icons/fa";
import { BsCloudUpload } from "react-icons/bs";
import { HiEye } from "react-icons/hi";
import moment from "moment";
import { useRef, useState } from "react";
import { uploadSingleFileApi } from "../../services/files";
import { FILE_TYPES } from "../../constants";
import { useDispatch } from "react-redux";
import { setLotesDelivered } from "../../store/appSlice";
import LoadingDots from "../Loading/LoadingDots";

const DespachosRow = ({ despacho, updateDespachos }) => {
  const {
    date,
    status,
    remisionNumber,
    bodegaDestination,
    remisionFirmadaFile,
  } = despacho;
  const [isLoading, setIsLoading] = useState(false);
  const { client } = bodegaDestination;
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const isFileSizeRigth = (fileSize) => {
    return fileSize < 5 * 1024 * 1024;
  };

  const onButtonClick = () => {
    fileInputRef.current.click();
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (isFileSizeRigth(file.size)) {
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("despachoId", despacho._id);
    try {
      const fileUploaded = await uploadSingleFileApi(
        FILE_TYPES.remisionesFirmadas,
        formData
      );
      setIsLoading(false);
      updateDespachos(fileUploaded.data.updatedValues);
      dispatch(setLotesDelivered(despacho));
    } catch (err) {
      console.log("Ness: err", err);
    }
  };

  return (
    <div className="grid grid-cols-5 h-12 justify-items-center items-center rounded-lg hover:bg-cultured">
      <p className="text-sm">{moment(date).format("DD MMMM YYYY")}</p>
      <p className="text-sm">{remisionNumber}</p>
      <p className="text-sm">{client.nombre}</p>
      <p
        className={`text-sm text-carrot-${
          status === "En transito" ? "orange" : "black"
        }`}
      >
        {status}
      </p>
      <div className="flex w-2/5 justify-between">
        <a href={despacho.remisionFile?.url} target="_blank" rel="noreferrer">
          <FaFilePdf className="cursor-pointer" />
        </a>
        <input
          type="file"
          className="input-upload"
          accept=".pdf,image/jpeg,image/png"
          ref={fileInputRef}
          onChange={onFileChange}
          disabled={remisionFirmadaFile?.url}
        />
        {isLoading ? (
          <LoadingDots />
        ) : (
          <BsCloudUpload
            className="cursor-pointer"
            onClick={onButtonClick}
            color={remisionFirmadaFile?.url ? "#bbb" : "black"}
          />
        )}
        <a href={remisionFirmadaFile?.url} target="_blank" rel="noreferrer">
          <HiEye
            className="cursor-pointer"
            color={remisionFirmadaFile?.url ? "black" : "#bbb"}
          />
        </a>
      </div>
    </div>
  );
};

export default DespachosRow;
