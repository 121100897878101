const HeaderAction = ({ heading, action }) => {
  return (
    <div className="HeaderAction">
      <h2>{heading}</h2>
      <div>{action}</div>
    </div>
  );
};

export default HeaderAction;
