import React, { useState, useEffect } from "react";
import Page from "../../layout/Page/Page";
import Input from "../../components/Input/Input";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteSurgeryCardValue,
  addNewSurgery,
  editSurgery,
  populateSurgeryFieldsEdit,
  setPageInputs,
  populatePageInputValues,
  handlePageInputValue,
  deleteSurgery,
  toggleShowModal,
} from "../../store/appSlice";
import Modal from "../../components/Modal/Modal";
import CreateForm from "../../components/CreateForm/CreateForm";
import Card from "../../components/Card/Card";
import Container from "../../components/Container/Container";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSurgeryApi } from "../../services/surgeries";
import { getPageInputs } from "../../content/elements/NewSurgery/pageInputs";
import Confirm from "../../components/Modals/Confirm/Confirm";
import Incrementor from "../../components/Incrementor/Incrementor";
import "./NewSurgery.css";

const NewSurgery = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [surgeryId, setSurgeryId] = useState(0);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [surgeryCreationDate, setSurgeryCreationDate] = useState(
    moment().format("DD MMMM YYYY")
  );
  const [surgeryModifyDate, setSurgeryModifyDate] = useState("");
  const content = useSelector((state) => state.app.content);
  const pageInputs = useSelector((state) => state.app.pageInputs);
  const showModal = useSelector((state) => state.app.showModal);
  const isSaveSurgeryEnabled = useSelector(
    (state) => state.app.isSaveSurgeryEnabled
  );
  const isEditSurgeryEnabled = useSelector(
    (state) => state.app.isEditSurgeryEnabled
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const surgery = location?.state?.surgery;
    setSurgeryId(params.surgery);
    if (location.pathname.includes("editar")) {
      setIsEdit(true);
      if (surgery) {
        if (pageInputs.length > 0) {
          setSurgeryCreationDate(
            moment(surgery.fecha_solicitud).format("DD MMMM YYYY")
          );
          setSurgeryModifyDate(
            surgery.updatedAt
              ? moment(surgery.updatedAt).format("DD MMMM YYYY hh:mm a")
              : moment(surgery.fecha_solicitud).format("DD MMMM YYYY hh:mm a")
          );
          dispatch(populateSurgeryFieldsEdit(surgery));
        } else {
          // FIXME: this is a hack to wait for the pageInputs to be populated
          setTimeout(() => {
            setIsEdit(true);
            dispatch(populateSurgeryFieldsEdit(surgery));
          }, 1500);
        }
      } else {
        // if surgery is not passed in routing, fetch it
        getSurgeryApi(params.surgery).then((res) => {
          // FIXME: this is a hack to wait for the pageInputs to be populated
          setTimeout(() => {
            dispatch(populateSurgeryFieldsEdit(res));
          }, 1500);
        });
      }
    } else {
      setIsEdit(false);
      dispatch(setPageInputs(getPageInputs(content)));
      dispatch(populatePageInputValues());
    }
  }, [location, dispatch, pageInputs.length, params.surgery, content]);

  const newSurgeryPageProps = {
    title: isEdit ? content.texts.EDIT_SURGERY : content.texts.NEW_SURGERY,
    subtitle: content.texts.SEARCH_OR_CREATE,
  };

  return (
    <Page>
      <div className="NewSurgery">
        <Container {...newSurgeryPageProps}>
          <div className="new-surgery-form">
            {pageInputs.map((input) => (
              <Input
                key={input.id}
                {...input}
                handleChange={(e) =>
                  dispatch(
                    handlePageInputValue({
                      id: input.id,
                      value: e.target.value,
                    })
                  )
                }
              />
            ))}
          </div>
          {isEdit && (
            <div className="delete-surgery">
              <Button
                label="Eliminar cirugia"
                variant="delete"
                leftIcon="trash"
                size="xxsmall"
                // click={() => dispatch(deleteSurgery(surgeryId))}
                click={() => setIsOpenConfirm(true)}
              />
            </div>
          )}
        </Container>
        <Card>
          <div className="new-surgery-summary">
            <div className="new-surgery-summary-header">
              <h2>{content.labels.SURGERY}</h2>
              <div className="summary-header-bottom">
                {/* <p>{content.labels.SUMMARY}</p> */}
                <p>{content.labels.CREATED}</p>
                <span>{surgeryCreationDate}</span>
              </div>
              {isEdit && (
                <div className="summary-header-updated">
                  <span>{content.texts.LAST_UPDATED}</span>
                  <span>{surgeryModifyDate}</span>
                </div>
              )}
            </div>
            <div className="new-surgery-summary-content">
              {pageInputs.map(
                (input) =>
                  input.hasSummary && (
                    <div
                      key={input.id}
                      className={input.isLast ? "summary-footer" : ""}
                    >
                      <p>{input.label}</p>
                      <div className="summary-value">
                        <span className="summary-value-label">
                          {input.selectedValues?.length > 0
                            ? input.selectedValues.map((element, index) => (
                                <div className="summary-materials" key={index}>
                                  <span className="summary-multi-value">
                                    {element.value}
                                  </span>
                                  <Incrementor
                                    {...input}
                                    item={element}
                                    index={index}
                                  />
                                </div>
                              ))
                            : input.isSelected && input.type === "date"
                            ? moment(input.value).format("DD MMMM YYYY hh:mm a")
                            : input.isSelected && input.value}
                        </span>
                        {input.isSelected && (
                          <span
                            onClick={() =>
                              dispatch(deleteSurgeryCardValue({ id: input.id }))
                            }
                            className="times-icon"
                          >
                            <FontAwesomeIcon icon="times" />
                          </span>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
            {/* FIXME: WHEN USING PURE COMPONENT (NESS UI LIB) PASS THE PROPS AND DO LOGIC ON THIS LEVEL */}
            {isEdit ? (
              <div className="edit-buttons-wrapper">
                <Button
                  variant="cancel"
                  label={content.labels.CANCEL}
                  click={() => navigate(-1)}
                />
                <Button
                  label={content.button.SAVE_CHANGES}
                  click={() => {
                    dispatch(
                      editSurgery(
                        pageInputs,
                        surgeryId,
                        location?.state?.surgery?.material_solicitado
                      )
                    ).then((surgery) => {
                      navigate(`/cirugias/${surgeryId}`, {
                        state: { surgery },
                      });
                    });
                  }}
                  disabled={!isEditSurgeryEnabled && !isSaveSurgeryEnabled}
                />
              </div>
            ) : (
              <Button
                label={content.button.SAVE_SURGERY}
                click={() => {
                  dispatch(addNewSurgery(pageInputs)).then((newSurgery) => {
                    navigate(`/cirugias/${newSurgery._id}`, {
                      state: { surgery: newSurgery, isNew: true },
                    });
                  });
                }}
                disabled={!isSaveSurgeryEnabled}
              />
            )}
          </div>
        </Card>
      </div>
      <Modal isOpen={showModal}>
        <CreateForm close={() => dispatch(toggleShowModal(false))} />
      </Modal>
      <Modal isOpen={isOpenConfirm}>
        <Confirm
          copy={content.texts.CONFIRM_DELETE_SURGERY}
          close={() => setIsOpenConfirm(false)}
          confirm={() => {
            dispatch(deleteSurgery(surgeryId)).then(() => {
              setIsOpenConfirm(false);
              navigate("/cirugias");
            });
          }}
        />
      </Modal>
    </Page>
  );
};

export default NewSurgery;
