import axios from "axios";
import { url } from ".";

export const checkLogin = async (email, password) => {
  try {
    const loggedUser = await axios.post(`${url}/auth/login`, {
      email,
      password,
    });
    return loggedUser.data;
  } catch (e) {
    throw e;
  }
};
