import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { logout } from "../../store/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { getNavigationItems } from "../../content/elements/navigationItems";
import LogoNess from "../Icons/Logo/LogoNess";
import LogoVive from "../Icons/Logo/LogoVive";
import "./Navigation.css";

const Navigation = () => {
  const content = useSelector((state) => state.app.content);
  const user = useSelector((state) => state.app.user);
  const dispatch = useDispatch();

  const navigationItems = getNavigationItems(content);

  return (
    <div className="Navigation">
      <div className="nav-header">
        <div className="flex justify-center">
          <LogoVive size="medium" />
        </div>
        <div className="nav-primary">
          <ul>
            {navigationItems
              .filter(
                (item) => !item.protected || item.protected === user?.role
              )
              .map((item) => (
                <div key={item.id} className="nav-item-wrapper">
                  <NavLink to={item.url}>
                    <li>
                      <FontAwesomeIcon icon={["fa", item.icon]} />
                      {item.name}
                    </li>
                  </NavLink>
                </div>
              ))}
          </ul>
        </div>
      </div>
      <div className="nav-footer">
        <div className="nav-secondary">
          <ul>
            <div className="nav-item-wrapper">
              <NavLink to="/ajustes">
                <li>
                  <FontAwesomeIcon icon={["fa", "cog"]} />
                  {content.labels.SETTINGS}
                </li>
              </NavLink>
            </div>
            <div className="nav-item-wrapper">
              <NavLink to="/salir" onClick={() => dispatch(logout())}>
                <li>
                  <FontAwesomeIcon icon={["fa", "sign-out-alt"]} />
                  {content.labels.LOGOUT}
                </li>
              </NavLink>
            </div>
          </ul>
        </div>
        <div className="nav-footer-note">
          <p className="text-[11px] font-extralight pb-px">Powered by</p>
          <LogoNess color="#8b8ea7" size="small" />
          {/* &copy; Ness {new Date().getFullYear()} */}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
