import React from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { isMobile } from "../utils/core";
import { useSelector } from "react-redux";

const MobileLayout = () => {
  const isLogged = useSelector((state) => state.app.isLogged);
  const user = useSelector((state) => state.app.user);

  if (!isMobile()) {
    return (
      <div className="only-on-mobile">
        <p>This page is only available on mobile devices</p>
        <Link to="/">Ir a la pagina principal</Link>
      </div>
    );
  }

  return isLogged && user?.role === "admin" ? (
    <div className="MobileLayout">
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default MobileLayout;
