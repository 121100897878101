import React from "react";
import { useDispatch } from "react-redux";
import {
  handleModalInputValue,
  handlePageInputValue,
} from "../../store/appSlice";
import "./InputTextarea.css";

const InputTextarea = ({ id, label, value, inputsParent }) => {
  const dispatch = useDispatch();

  const handleInputValue = (e) => {
    if (inputsParent === "pageInputs") {
      dispatch(
        handlePageInputValue({
          id,
          value: e.target.value,
        })
      );
    } else {
      dispatch(handleModalInputValue({ value: e.target.value, id }));
    }
  };

  return (
    <div className="InputTextarea">
      <textarea name={label} value={value} onChange={handleInputValue}>
        {value}
      </textarea>
    </div>
  );
};

export default InputTextarea;
