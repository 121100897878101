import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileApp = () => {
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const ws = useSelector((state) => state.app.ws);
  const navigate = useNavigate();

  useEffect(() => {
    // FIXME: TEMP ADD TIMEOUT TO ALLOW WS TO CONNECT
    setTimeout(() => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.onmessage = (event) => {
          const messageFromServer = event.data;
          console.log("(Mobile App) WS server:", messageFromServer);
          if (messageFromServer === "open_session") {
            setIsSessionOpen(true);
            ws.send("session_open");
          }
        };
      }
    }, 1000);
  }, [ws]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <span className="w-[50px]"></span>
        <h1 className="text-lg text-center mt-5">Mobile Dashboard</h1>
        <img
          onClick={() => setMenuOpen(true)}
          src="./assets/images/menu.png"
          className="w-8 h-8 mr-8"
          alt=""
        />
      </div>
      <div className="w-[70%] bg-gray-300 h-4 mx-auto mt-8 rounded-[6px]"></div>
      <div className="w-[70%] bg-gray-300 h-4 mx-auto mt-4 rounded-[6px]"></div>
      <div className="w-[70%] bg-gray-300 h-4 mx-auto mt-4 rounded-[6px]"></div>
      {menuOpen && (
        <div className="bg-white w-[88%] h-[100%] absolute right-0 top-0 p-10">
          <p
            className="float-right underline"
            onClick={() => setMenuOpen(false)}
          >
            Close
          </p>
          <p>Dashboard</p>
          <p
            className={`mt-4 ${isSessionOpen ? "" : "text-gray-400"}`}
            onClick={() => isSessionOpen && navigate("/escaner")}
          >
            Escaner
          </p>
        </div>
      )}
    </div>
  );
};

export default MobileApp;
