import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import {
  openCreateModal,
  focusPageInput,
  handlePageSearchInputValue,
  clearPageInputFocus,
} from "../../store/appSlice";
import SearchSuggestion from "../SearchSuggestion/SearchSuggestion";
import useOutsideClick from "../../hooks/useUnfocus";
import "./InputSearch.css";

const InputSearch = ({
  id,
  name,
  title,
  label,
  value,
  values,
  maxLength,
  canCreate,
  isSelected,
  isFocused,
  isLast,
  canIncrement,
  db_identifier,
  inputsParent,
  size,
}) => {
  const content = useSelector((state) => state.app.content);
  const suggestions = useSelector((state) => state.app.suggestions);
  const dispatch = useDispatch();
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    if (isFocused) dispatch(clearPageInputFocus());
  });

  return (
    <div
      ref={ref}
      className={`InputSearch ${
        inputsParent === "modalInputs" && "modal-inputSearch"
      } ${size}`}
    >
      <input
        type="text"
        name={label}
        maxLength={maxLength}
        value={value}
        autoComplete="off"
        // onFocus={() => dispatch(focusPageInput({ id, inputs: inputsParent }))}
        onChange={(e) => {
          dispatch(focusPageInput({ id, inputs: inputsParent }));
          dispatch(
            handlePageSearchInputValue({
              value: e.target.value,
              id,
              values,
              inputs: inputsParent,
            })
          );
        }}
      />
      {canCreate &&
        isFocused &&
        (suggestions.length < 1 || value === "") &&
        !isSelected && (
          <span
            className="create-in-input"
            onClick={() =>
              dispatch(
                openCreateModal({
                  id,
                  name,
                  label: title ?? label,
                  value,
                  db_identifier,
                })
              )
            }
          >
            {content.labels.CREATE} <FontAwesomeIcon icon="plus-circle" />
          </span>
        )}
      <SearchSuggestion
        id={id}
        value={value}
        isFocused={isFocused}
        isSelected={isSelected}
        isLast={isLast}
        inputsParent={inputsParent}
        canIncrement={canIncrement}
      />
    </div>
  );
};

export default InputSearch;
