import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendarMinus,
  faCalendarCheck,
} from "@fortawesome/free-regular-svg-icons";
import { PropTypes } from "prop-types";
import "./Tabs.css";

const Tabs = ({ tabs, setTab }) => {
  return (
    <div className="Tabs">
      {tabs.map((tab) => (
        <span
          key={tab.id}
          className={`${tab.active ? "surgeries-tab-selected" : ""}`}
          onClick={() => setTab(tab)}
        >
          <FontAwesomeIcon
            icon={
              tab.name === "soon"
                ? faClock
                : tab.name === "nogasto"
                ? faCalendarMinus
                : faCalendarCheck
            }
            className="tab-icon"
          />
          {tab.title}
        </span>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  setTab: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  tabs: [],
  setTab: () => {},
};

export default Tabs;
