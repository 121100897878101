import React, { useState } from "react";
import QrReader from "modern-react-qr-reader";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import "./Scanner.css";

// https://github.com/react-qr-reader/react-qr-reader
// https://github.com/KhaosArbiter/modern-react-qr-reader

const Scanner = () => {
  const [showScanner, setShowScanner] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const ws = useSelector((state) => state.app.ws);

  return (
    <div className="Scanner">
      <div className="scanner-header">
        <h1>Escanear</h1>
        <p>Escanea el codigo del producto</p>
      </div>
      <div className="scanner-main">
        <div className="camera-container">
          <div className="camera">
            <div className="video">
              {showScanner && (
                <QrReader
                  delay={300}
                  facingMode={"environment"}
                  onError={(err) => err && setError(err.message)}
                  onScan={(data) => data && setCode(data)}
                  style={{ width: "100%" }}
                />
                // <QrReader
                //   facingMode={"rear"}
                //   onResult={(result, error) => {
                //     if (!!result) {
                //       setCode(result?.text);
                //     }

                //     if (!!error) {
                //       setError(error.message);
                //     }
                //   }}
                // />
              )}
              {code !== "" && (
                <div className="scanner-success">
                  <span className="tick-wrapper">
                    <img src="./assets/images/tick.svg" alt="tick icon" />
                  </span>
                  <p>Codigo registrado</p>
                  <p className="code">{code}</p>
                  <Button
                    label="OK"
                    click={() => {
                      ws.send(
                        JSON.stringify({
                          type: "scanned_lote",
                          id: 1,
                          codigo: "ESC",
                          lote: "MVIOUT77",
                          descripcion: "Putto 100",
                          fechaVencimiento: "24 Diciembre",
                        })
                      );
                      setCode("");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="scanner-footer">
        <p>{error}</p>
        <div className="action-wrapper" onClick={() => setShowScanner(true)}>
          <img src="./assets/images/scanner.svg" alt="scanner" />
        </div>
        <p>Abrir escaner</p>
      </div>
    </div>
  );
};

export default Scanner;
