import React, { useEffect } from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { addNewEntity, handleModalInputValue } from "../../store/appSlice";
import "./CreateForm.css";

const CreateForm = ({ close }) => {
  const content = useSelector((state) => state.app.content);
  const modalHeading = useSelector((state) => state.app.modalHeading);
  const entityDbId = useSelector((state) => state.app.entityDbId);
  const selectedEntityId = useSelector((state) => state.app.selectedEntityId);
  const modalInputs = useSelector((state) => state.app.modalInputs);
  const isSaveEntityEnabled = useSelector(
    (state) => state.app.isSaveEntityEnabled
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const closeModal = (e) => {
      if (e.key === "Escape") {
        close();
      }
    };
    window.addEventListener("keydown", closeModal);
    return () => window.removeEventListener("keydown", closeModal);
  }, [close, content]);

  return (
    <div className="CreateForm">
      <div className="form-header">
        <h2 className="form-heading">
          {content.labels.CREATE} {modalHeading.toLowerCase()}
        </h2>
        <div className="close-form" onClick={() => close(false)}>
          <FontAwesomeIcon icon="times-circle" />
        </div>
      </div>
      <div className="form-content">
        {modalInputs &&
          modalInputs.map((item) => (
            <Input
              {...item}
              key={item.id}
              handleChange={(e) =>
                dispatch(
                  handleModalInputValue({ id: item.id, value: e.target.value })
                )
              }
            />
          ))}
      </div>
      <div className="form-footer">
        <Button
          label={content.labels.CANCEL}
          variant="cancel"
          click={() => close(false)}
        />
        <Button
          label={content.labels.CREATE}
          disabled={!isSaveEntityEnabled}
          click={() => {
            dispatch(addNewEntity(modalInputs, entityDbId, selectedEntityId));
          }}
        />
      </div>
    </div>
  );
};

export default CreateForm;
