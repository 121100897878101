import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateLayout from "../layout/PrivateLayout/PrivateLayout";
import PublicLayout from "../layout/PublicLayout/PublicLayout";
// import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import NewSurgery from "../pages/NewSurgery/NewSurgery";
import NotFound from "../pages/NotFound/NotFound";
import Scanner from "../pages/Scanner/Scanner";
import Settings from "../pages/Settings/Settings";
import Surgeries from "../pages/Surgeries/Surgeries";
import Surgery from "../pages/Surgery/Surgery";
import Warehouses from "../pages/ReactInventory/Warehouses";
import Warehouse from "../pages/ReactInventory/Warehouse";
import LotNumbers from "../pages/ReactInventory/LotNumbers";
import LotNumber from "../pages/ReactInventory/LotNumber";
import Shipment from "../pages/ReactInventory/Shipment";
import Shipments from "../pages/ReactInventory/Shipments";
import Ingresos from "../pages/Ingresos/Ingresos";
import NewIngreso from "../components/Ingresos/NewIngreso";
import ProtectedLayout from "../layout/ProtectedLayout";
import MobileLayout from "../layout/MobileLayout";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route element={<PrivateLayout />}>
          <Route element={<ProtectedLayout />}>
            <Route path="/ingresos" element={<Ingresos />} />
            <Route path="/ingresos/crear-ingreso" element={<NewIngreso />} />
          </Route>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Navigate to="/cirugias" />} />
          <Route path="/cirugias" element={<Surgeries />} />
          <Route path="/cirugias/:surgery" element={<Surgery />} />
          <Route path="/cirugias/crear-cirugia" element={<NewSurgery />} />
          <Route
            path="/cirugias/editar-cirugia/:surgery"
            element={<NewSurgery />}
          />
          <Route path="/bodegas" element={<Warehouses />} />
          <Route path="/bodegas/:id" element={<Warehouse />} />
          <Route path="/bodegas/:id/lotes/" element={<LotNumbers />} />
          <Route path="/bodegas/lotes/:id" element={<LotNumber />} />
          <Route path="/despachos" element={<Shipments />} />
          <Route path="/despachos/:id" element={<Shipment />} />
          <Route path="/ajustes" element={<Settings />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<MobileLayout />}>
          <Route path="/escaner" element={<Scanner />} />
        </Route>
        <Route element={<PublicLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;
