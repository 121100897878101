import React, { useEffect, useState } from "react";
import Page from "../../layout/Page/Page";
import Container from "../Container/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Input/Input";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import moment from "moment";
import { addIngresoApi } from "../../services/ingresos";
import Confirm from "../Modals/Confirm/Confirm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IngresosLotesModal from "./IngresosLotesModal";
import { openNewIngresoSession } from "../../store/appSlice";
import "./NewIngreso.css";

const NewIngreso = () => {
  const [isScanner, setIsScanner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [entries, setEntries] = useState([]);
  const [invoiceRemision, setInvoiceRemision] = useState("");
  const [supplier, setSupplier] = useState("");
  const [loteCode, setLoteCode] = useState("");
  const [description, setDescription] = useState("");
  const [product, setProduct] = useState("");
  const [lote, setLote] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [quantity, setQuantity] = useState("");
  const [totalIngresos, setTotalIngresos] = useState(0);
  const [isEdit, setIsEdit] = useState("");
  const [confirmIngreso, setConfirmIngreso] = useState(false);
  const creationDate = moment().format("DD MMMM YYYY");
  const [descriptionFocus, setDescriptionFocus] = useState(false);
  const [codeFocus, setCodeFocus] = useState(false);
  const [addIngresoLoading, setAddIngresoLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [scannedLotes, setScannedLotes] = useState([]);
  const materials = useSelector((state) => state.app.materials);
  const isNewIngresoOpen = useSelector((state) => state.app.isNewIngresoOpen);
  const ws = useSelector((state) => state.app.ws);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    ws.send("session_open");
    ws.onmessage = (event) => {
      const messageFromServer = event.data;
      console.log("(New Ingreso) WS server:", messageFromServer);
      if (messageFromServer === "open_session") {
        dispatch(openNewIngresoSession(true));
      } else if (messageFromServer.includes("scanned_lote")) {
        setScannedLotes((prevScannedLotes) => {
          const newScannedLote = JSON.parse(messageFromServer);
          return [...prevScannedLotes, newScannedLote];
        });
      }
    };
  }, [ws, dispatch]);

  return (
    <Page>
      <div className="NewIngreso flex justify-between">
        <Container
          title="Ingreso de lotes"
          className="w-[70%]"
          renderComponent={() => (
            <FormAndScanner
              isScanner={isScanner}
              setIsScanner={setIsScanner}
              isNewIngresoOpen={isNewIngresoOpen}
            />
          )}
        >
          <main>
            {isScanner ? (
              <table className="mt-8 min-w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-left font-medium underline">
                      Código
                    </th>
                    <th className="p-2 text-left font-medium underline">
                      Descripción
                    </th>
                    <th className="p-2 text-left font-medium underline">
                      Lote
                    </th>
                    <th className="p-2 text-right font-medium underline">
                      Fecha de vencimiento
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {scannedLotes.map((lote) => (
                    <tr key={lote.id}>
                      <td className="p-2 font-light text-sm">{lote.codigo}</td>
                      <td className="p-2 font-light text-sm">
                        {lote.descripcion}
                      </td>
                      <td className="p-2 font-light text-sm">{lote.lote}</td>
                      <td className="p-2 font-light text-sm text-right">
                        {lote.fechaVencimiento}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <form className="max-w-[500px]">
                <div
                  className={`flex justify-between w-[600px] ${
                    !!entries.length && isEdit === "edit" ? "mb-[68px]" : ""
                  }`}
                >
                  {!!entries.length && isEdit === "edit" ? (
                    <>
                      <div className="flex justify-between w-[300px] text-sm mt-6">
                        <div>
                          <p className="font-medium">No. factura / remisión</p>
                          <p className="text-xs">{invoiceRemision}</p>
                        </div>
                        <div>
                          <p className="font-medium">Proveedor</p>
                          <p className="text-xs">{supplier}</p>
                        </div>
                      </div>
                      <p
                        className="link mt-8"
                        onClick={() => setIsEdit("save")}
                      >
                        Editar
                      </p>
                    </>
                  ) : (
                    <>
                      <Input
                        size="small"
                        type="text"
                        label="No. factura / remisión"
                        value={invoiceRemision}
                        handleChange={(e) => setInvoiceRemision(e.target.value)}
                      />
                      <Input
                        size="small"
                        type="text"
                        label="Proveedor"
                        length="w-300"
                        value={supplier}
                        handleChange={(e) => setSupplier(e.target.value)}
                      />
                      {!!entries.length ? (
                        <p
                          className="link mt-8 h-fit"
                          onClick={() => setIsEdit("edit")}
                        >
                          Guardar
                        </p>
                      ) : (
                        <span className="w-[60px]"></span>
                      )}
                    </>
                  )}
                </div>
                <div className="relative">
                  <Input
                    type="text"
                    label="Codigo"
                    value={loteCode}
                    onFocus={() => setCodeFocus(true)}
                    onBlur={() => setCodeFocus(false)}
                    handleChange={(e) => {
                      let codeVal = e.target.value;
                      setLoteCode(codeVal);
                      setSuggestions(
                        materials.filter((suggestion) =>
                          suggestion?.codigo
                            .toLowerCase()
                            .includes(codeVal.toLowerCase())
                        )
                      );
                    }}
                  />
                  {!!suggestions.length && (
                    <ReferenceSuggestions
                      materials={materials}
                      field={loteCode}
                      inputFocus={codeFocus}
                      suggestions={suggestions}
                      setDescription={setDescription}
                      setLoteCode={setLoteCode}
                      setProduct={setProduct}
                    />
                  )}
                </div>
                <div className="relative">
                  <Input
                    type="text"
                    label="Description"
                    value={description}
                    onFocus={() => setDescriptionFocus(true)}
                    onBlur={() => setDescriptionFocus(false)}
                    handleChange={(e) => {
                      let descriptionVal = e.target.value;
                      setDescription(descriptionVal);
                      setSuggestions(
                        materials.filter((suggestion) =>
                          suggestion?.nombre
                            .toLowerCase()
                            .includes(descriptionVal.toLowerCase())
                        )
                      );
                    }}
                  />
                  {!!suggestions.length && (
                    <ReferenceSuggestions
                      materials={materials}
                      field={description}
                      inputFocus={descriptionFocus}
                      suggestions={suggestions}
                      setDescription={setDescription}
                      setLoteCode={setLoteCode}
                      setProduct={setProduct}
                    />
                  )}
                </div>
                <Input
                  type="text"
                  label="Lote"
                  value={lote}
                  handleChange={(e) => setLote(e.target.value)}
                />
                <div className="max-w-[250px]">
                  <Input
                    type="date"
                    label="Fecha de vencimiento"
                    value={expiryDate}
                    handleChange={(e) => setExpiryDate(e.target.value)}
                  />
                  <Input
                    type="text"
                    label="Cantidad"
                    value={quantity}
                    handleChange={(e) => setQuantity(e.target.value)}
                  />
                </div>
                <div className="mt-8">
                  <Button
                    disabled={
                      invoiceRemision === "" ||
                      supplier === "" ||
                      loteCode === "" ||
                      description === "" ||
                      lote === "" ||
                      expiryDate === ""
                    }
                    label="Ingresar"
                    click={(e) => {
                      e.preventDefault();
                      const entryQuantity =
                        parseInt(quantity) > 0 ? parseInt(quantity) : 1;
                      setEntries((prev) => [
                        ...prev,
                        {
                          loteCode,
                          description,
                          product,
                          lote,
                          expiryDate,
                          quantity: entryQuantity,
                        },
                      ]);
                      setTotalIngresos(totalIngresos + entryQuantity);
                      setLoteCode("");
                      setDescription("");
                      setLote("");
                      setExpiryDate("");
                      setQuantity("");
                      setIsEdit("edit");
                    }}
                  />
                  <p className="text-sm mt-2 text-[#B3B5C2] font-medium">
                    Esta acción pasará los lotes al resumen
                  </p>
                </div>
              </form>
            )}
          </main>
        </Container>
        <div className="flex flex-col justify-between container w-[32%]">
          <div>
            <div className="flex justify-between items-center">
              <h2 className="text-[1.6em] font-medium">Resumen</h2>
              <p className="text-sm">{creationDate}</p>
            </div>
            <div className="text-xs">
              <p>
                <strong>Factura</strong>: Fact/Rem. {invoiceRemision}
              </p>
              <p>
                <strong>Proveedor</strong>: {supplier}
              </p>
            </div>
            <div className="quantity-circle2">
              <p className="quantity-number">{totalIngresos}</p>
              <p>Ingresados</p>
            </div>
            {!!entries.length && (
              <div className="border-[1px] p-[10px] border-[#B3B5C2] rounded-[7px] mt-8 max-h-auto overflow-auto">
                {entries.map((entry, index) => (
                  <div key={index} className="text-sm my-4">
                    <div className="flex justify-between font-medium">
                      <p>{entry.description}</p>
                      <p>{entry.quantity}</p>
                    </div>
                    <p className="font-light">{entry.loteCode}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          {!!entries.length && (
            <div className="flex justify-between items-end mt-8">
              <span className="link" onClick={() => setIsOpen(true)}>
                Ver listado de lotes
              </span>
              <Button
                click={() => {
                  setConfirmIngreso(true);
                }}
                label="Confirmar"
              />
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isOpen}>
        <IngresosLotesModal
          setIsOpen={setIsOpen}
          entries={entries}
          removeLote={(selectedLote) => {
            const updatedEntries = [...entries];
            const entryToUpdate = updatedEntries.find(
              (entry) => entry.lote === selectedLote
            );
            entryToUpdate.quantity -= 1;
            setEntries(updatedEntries);
            setTotalIngresos((prev) => prev - 1);
          }}
        />
      </Modal>
      <Modal isOpen={confirmIngreso}>
        <Confirm
          copy="Estas seguro que deseas realizar este ingreso?"
          close={() => setConfirmIngreso(false)}
          isLoading={addIngresoLoading}
          confirm={() => {
            setAddIngresoLoading(true);
            addIngresoApi({
              date: creationDate,
              supplier,
              invoiceRemision,
              lotes: entries,
            }).then((res) => {
              setAddIngresoLoading(false);
              console.log("GZ res", res);
              if (res !== undefined) {
                navigate(`/ingresos`);
              }
            });
          }}
        />
      </Modal>
    </Page>
  );
};

const ReferenceSuggestions = ({
  materials,
  field,
  inputFocus,
  suggestions,
  setDescription,
  setLoteCode,
  setProduct,
}) => {
  return (
    <div className="SearchSuggestion">
      <div
        className={`suggestion-container suggestion-container2 !w-[100%] ${
          materials?.length > 0 && field !== "" && inputFocus
            ? "show-suggestions"
            : ""
        }`}
        style={{ position: "absolute", zIndex: 10, marginTop: -10 }}
      >
        {suggestions?.map((suggestion) => (
          <div
            key={suggestion._id}
            className="suggestion suggestion2"
            onMouseDown={() => {
              setDescription(suggestion.nombre);
              setLoteCode(suggestion.codigo);
              setProduct(suggestion._id);
            }}
          >
            <p>{suggestion.nombre}</p>
            <p className="secondary-info">{suggestion.codigo}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const FormAndScanner = ({ isScanner, setIsScanner, isNewIngresoOpen }) => {
  return (
    <div className="flex justify-between items-center text-sm">
      <span
        className={`p-[5px] border border-1 border-[#E3E3E3] cursor-pointer ${
          isScanner ? "bg-white" : "bg-[#1D1F31] text-white"
        } rounded-tl-[6px] rounded-bl-[6px]`}
        onClick={() => setIsScanner(false)}
      >
        Formulario <FontAwesomeIcon icon={["fa", "sign-out-alt"]} />
      </span>
      <span
        className={`p-[5px] border border-1 border-[#E3E3E3] ${
          isScanner ? "bg-[#1D1F31] text-white" : "bg-white"
        } cursor-pointer rounded-tr-[6px] rounded-br-[6px]`}
        onClick={() => setIsScanner(true)}
      >
        {isNewIngresoOpen && (
          <span className="bg-green-500 block w-5 h-5 rounded-[50%]"></span>
        )}
        Escaner <FontAwesomeIcon icon={["fa", "sign-out-alt"]} />
      </span>
    </div>
  );
};

export default NewIngreso;
