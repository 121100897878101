export const getModalInputs = (content, name) => {
  const modalInputs = {
    Client: [
      {
        id: Math.random().toString(16).slice(2),
        identifier: "nombre",
        label: content.labels.NAME,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "tipo_id",
        label: content.labels.ID_TYPE,
        type: "dropdown",
        value: "NIT", // FIXME: default value should match the first option
        values: ["NIT", "Cédula"],
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "nit",
        label: content.labels.ID_NUMBER,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "ciudad",
        label: content.labels.CITY,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "direccion",
        label: content.labels.ADDRESS,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "telefono",
        label: content.labels.TELEPHONE,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "email",
        label: content.labels.EMAIL,
        value: "",
        type: "text",
      },
    ],
    Clinic: [
      {
        id: Math.random().toString(16).slice(2),
        identifier: "nombre",
        label: content.labels.NAME,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "ciudad",
        label: content.labels.CITY,
        value: "",
        type: "text",
        isMandatory: true,
      },
      // TODO: no usar por ahora
      // {
      //   id: Math.random().toString(16).slice(2),
      //   identifier: "cliente",
      //   label: content.labels.CLIENT,
      //   value: "",
      //   type: "search",
      //   helptext: content.texts.CLIENT_HELP_TEXT,

      //   entity_id: 0,
      //   parsedIdentifier: "client",
      //   db_identifier: "clients",
      //   name: "Client",
      //   suggestion1: "nombre",
      //   suggestion2: "nit",
      //   isMandatory: true,
      //   isFocused: false,
      //   isSelected: false,
      //   values: [],
      //   inputsParent: "modalInputs",
      // },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "direccion",
        label: content.labels.ADDRESS,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "telefono",
        label: content.labels.TELEPHONE,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "email",
        label: content.labels.EMAIL,
        value: "",
        type: "text",
      },
    ],
    Patient: [
      {
        id: Math.random().toString(16).slice(2),
        identifier: "nombres",
        label: content.labels.NAMES,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "apellidos",
        label: content.labels.LAST_NAMES,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "tipo_id",
        label: content.labels.ID_TYPE,
        value: "Cédula",
        type: "dropdown",
        values: [
          "Cédula",
          "Tarjeta de identidad",
          "Registro civil",
          "Pasaporte",
          "Cédula de Extranjería",
        ],
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "numero_id",
        label: content.labels.ID_NUMBER,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "direccion",
        label: content.labels.ADDRESS,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "telefono",
        label: content.labels.TELEPHONE,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "email",
        label: content.labels.EMAIL,
        value: "",
        type: "text",
      },
    ],
    Specialist: [
      {
        id: Math.random().toString(16).slice(2),
        identifier: "nombres",
        label: content.labels.NAMES,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "apellidos",
        label: content.labels.LAST_NAMES,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "especialidad",
        label: content.labels.SPECIALITY,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "direcccion-consultorio",
        label: content.labels.OFFICE_ADDRESS,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "telefono",
        label: content.labels.TELEPHONE,
        value: "",
        type: "text",
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "email",
        label: content.labels.EMAIL,
        value: "",
        type: "text",
      },
    ],
    Material: [
      {
        id: Math.random().toString(16).slice(2),
        identifier: "codigo",
        label: content.labels.CODE,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "nombre",
        label: content.labels.NAME,
        value: "",
        type: "text",
        isMandatory: true,
      },
      {
        id: Math.random().toString(16).slice(2),
        identifier: "notes",
        label: content.labels.NOTES,
        value: "",
        type: "textarea",
      },
    ],
  };

  return modalInputs[name];
};
