import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import exampleReducer from "./Example/exampleSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    example: exampleReducer,
    // TODO: implement multiple slices (parts of the project)
    /* users: usersReducer,
        submissions: submissionsReducer,
        topics: topicsReducer */
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
