import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareDown } from "@fortawesome/free-solid-svg-icons";
import "./DropdownSearch.css";

const DropdownSearch = ({
  suggestions,
  label,
  findField,
  findField2,
  prependField2,
  placeholder,
  isMandatory,
  isRelative,
  isFullWidth,
  hideTitle,
  disabled,
  onSelect,
  onShowAll,
  selectedItem,
}) => {
  const [value, setValue] = useState("");
  const [items, setItems] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setItems(suggestions);
  }, [suggestions]);

  const filterItems = (e) => {
    setValue(e.target.value);
    let filteredItems = suggestions.filter(
      (item) =>
        item[findField].toLowerCase().includes(e.target.value.toLowerCase()) ||
        item[findField2].toLowerCase().includes(e.target.value.toLowerCase())
    );
    setItems(filteredItems);
  };

  const renderSelected = () => {
    if (selectedItem !== undefined) {
      return selectedItem ? (
        <>
          <p>{selectedItem.name}</p>
          <p className="subinfo">{selectedItem.info}</p>
        </>
      ) : (
        <p className="item-placeholder">{placeholder}</p>
      );
    }
    return Object.keys(itemSelected).length < 1 ? (
      <p className="item-placeholder">{placeholder}</p>
    ) : (
      <>
        <p>{itemSelected.name}</p>
        <p className="subinfo">
          {prependField2} {itemSelected.info}
        </p>
      </>
    );
  };

  return (
    <div
      ref={wrapperRef}
      className={`DropdownSearch ${isFullWidth ? "full-width" : ""}`}
    >
      {!hideTitle && (
        <div className="label-wrapper">
          <p className="label">
            {isMandatory && <span className="isMandatory-star">*</span>}
            {label}
          </p>
          {onShowAll && (
            <p
              className="show-all"
              onClick={() => {
                setItemSelected({});
                onShowAll();
              }}
            >
              Mostrar todas
            </p>
          )}
        </div>
      )}
      <div
        className={`input ${disabled ? "opacity-50" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="input-label">{renderSelected()}</div>
        <div>
          <FontAwesomeIcon icon={faCaretSquareDown} color="#8F95B2" />
        </div>
      </div>
      {isOpen && !disabled && (
        <div
          className={`suggestions ${isRelative ? "suggestions-relative" : ""}`}
        >
          <div className="search-topbar">
            <FontAwesomeIcon icon="search" />
            <input
              type="text"
              placeholder={`Buscar ${label ? label.toLowerCase() : ""}`}
              onChange={filterItems}
              value={value}
            />
          </div>
          <div className="suggestions-list">
            <p className="suggestions-list-label">Sugerencias</p>
            {items.slice(0, 5).map((item) => (
              <div
                key={item._id}
                className="suggestions-item"
                onClick={(e) => {
                  e.stopPropagation();
                  let selected = {
                    id: item._id,
                    name: item[findField],
                    info: item[findField2],
                  };
                  setItemSelected(selected);
                  onSelect(e, { ...item, info: item[findField2] });
                  setIsOpen(false);
                }}
              >
                <p className="name">{item[findField]}</p>
                <p className="info">
                  {prependField2} {item[findField2]}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
