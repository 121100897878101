import React from "react";
import "./Container.css";

const Container = ({
  title,
  subtitle,
  renderComponent,
  children,
  className,
  mainTop = true,
}) => {
  return (
    <div className={`Container ${className}`}>
      <div className="container-header">
        <div className="container-headings">
          <h1 className="container-title">{title}</h1>
          <h2 className="container-subtitle">{subtitle}</h2>
        </div>
        {renderComponent && renderComponent()}
      </div>
      <main style={{ marginTop: mainTop ? "40px" : 0 }}>{children}</main>
    </div>
  );
};

export default Container;
