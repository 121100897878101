import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FaSearch } from "react-icons/fa";

const IngresosLotesModal = ({ setIsOpen, entries, removeLote }) => {
  const LotesRows = ({ index, ingreso }) => {
    return (
      <tr key={index}>
        <td className="p-1 font-light text-sm">{ingreso.loteCode}</td>
        <td className="p-1 font-light text-sm">{ingreso.description}</td>
        <td className="p-1 font-light text-sm">{ingreso.lote}</td>
        {removeLote && (
          <td className="">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon="trash"
              color="#8F95B2"
              onClick={() => {
                removeLote(ingreso.lote);
              }}
            />
          </td>
        )}
      </tr>
    );
  };
  return (
    <div className="w-[800px] p-7 min-h-[500px]">
      <div className="flex justify-between items-center">
        <span className="basis-[5%]"></span>
        <p className="basis-[60%] text-center text-[1.5em] font-semibold">
          Listado de lotes
        </p>
        <div
          className="basis-[5%] close-modal-container text-right"
          onClick={() => setIsOpen(false)}
        >
          <FontAwesomeIcon icon="times-circle" />
        </div>
      </div>
      <div className="w-3/4 mx-auto mt-8">
        <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
          <FaSearch className="fill-cool-grey mr-2" />
          <input
            className="w-full border-none outline-none placeholder:text-cool-grey"
            type="text"
            placeholder="Buscar lotes"
          />
        </div>
      </div>
      <table className="mt-8 min-w-full">
        <thead>
          <tr>
            <th className="p-2 text-left font-medium">Código</th>
            <th className="p-2 text-left font-medium">Referencia</th>
            <th className="p-2 text-left font-medium">Lote</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((ingreso, index) => {
            if (ingreso.quantity) {
              return Array.from({ length: ingreso.quantity }).map(
                (_, innerIndex) => (
                  <LotesRows
                    index={`${index}_${innerIndex}`}
                    ingreso={ingreso}
                  />
                )
              );
            }
            return <LotesRows index={index} ingreso={ingreso} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IngresosLotesModal;
