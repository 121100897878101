import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DropdownSearch from "../../DropdownSearch/DropdownSearch";
import "./AddLotes.css";

const AddLotes = ({
  selectedMaterial,
  selectedLotes,
  lotesData,
  bodegasData,
  materials,
  updateMaterialLotes,
  setShowAddMaterial,
  swapLote,
}) => {
  const [bodega, setBodega] = useState({});
  const [bodegas, setBodegas] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredLotes, setFilteredLotes] = useState(lotesData);

  useEffect(() => {
    let parsedBodegas = bodegasData.map((bod) => ({
      ...bod,
      client: bod.client.nombre,
    }));
    setBodegas(parsedBodegas);
  }, [bodegasData]);

  useEffect(() => {
    setFilteredLotes(lotesData);
  }, [lotesData]);

  const filterSearchLote = (searchTerm) => {
    return lotesData.filter(
      (lote) => lote.lote.toLowerCase().indexOf(searchTerm) > -1
    );
  };

  const filterLotes = ({ selectedBodega, searchTerm, showAll }) => {
    let searchVal = searchTerm !== undefined ? searchTerm : searchValue;
    if (showAll) {
      return filterSearchLote(searchVal);
    }
    let bodegaId =
      selectedBodega !== undefined
        ? selectedBodega._id
        : bodega?._id
        ? bodega._id
        : "";
    if (bodegaId === "") {
      return filterSearchLote(searchVal);
    }
    return lotesData.filter(
      (lote) =>
        lote.lote.toLowerCase().indexOf(searchVal) > -1 &&
        lote.bodegaId === bodegaId
    );
  };

  const selectLote = (lote) => {
    let findMaterial = materials.find(
      (material) => material.producto._id === lote.product._id
    );
    if (swapLote) {
      findMaterial.lotes = findMaterial.lotes.map((existingLote) =>
        existingLote._id === swapLote._id ? lote : existingLote
      );
    } else {
      findMaterial.lotes = findMaterial.lotes
        ? [...findMaterial.lotes, lote]
        : [lote];
    }
    let updatedMaterials = materials.map((material) =>
      material._id === findMaterial._id ? findMaterial : material
    );
    updateMaterialLotes(updatedMaterials);
    setShowAddMaterial(false);
  };

  return (
    <div className="AddLotes">
      <h3>
        {selectedMaterial.producto?.nombre} -{" "}
        {selectedMaterial.producto?.codigo}
      </h3>
      <DropdownSearch
        label="Bodega origen"
        findField="name"
        findField2="client"
        placeholder="Todas las bodegas"
        suggestions={bodegas}
        onSelect={(e, selectedBodega) => {
          e.stopPropagation();
          setBodega(selectedBodega);
          setFilteredLotes(filterLotes({ selectedBodega }));
        }}
        onShowAll={() => {
          setBodega({});
          setFilteredLotes(filterLotes({ showAll: true }));
        }}
      />
      <div className="simple-list-container">
        <div className="container-list-search">
          <FontAwesomeIcon icon="search" />
          <input
            type="text"
            placeholder="Buscar lote"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setFilteredLotes(filterLotes({ searchTerm: e.target.value }));
            }}
          />
        </div>
        <div className="container-list-header">
          <p>Lote</p>
          <p>Bodega</p>
        </div>
        <div className="container-list-items">
          {filteredLotes.length > 0 ? (
            filteredLotes
              .filter((lote) => !selectedLotes.find((l) => l._id === lote._id))
              .filter((lote) =>
                bodega.id ? lote.bodegaId === bodega.id : lote
              )
              .filter((lote) => !lote.deletedAt)
              .map((lote) => (
                <div
                  key={lote._id}
                  className="container-list-row"
                  onClick={() => {
                    if (!lote.inTransit) {
                      selectLote(lote);
                      setSearchValue("");
                    }
                  }}
                >
                  {lote.inTransit && <p className="in-transit">en transito</p>}
                  <div className={lote.inTransit ? "lote-in-transit" : ""}>
                    <p>{lote.lote}</p>
                    <p className="list-row-secondary">
                      {moment(lote.expiryDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <div className={lote.inTransit ? "lote-in-transit" : ""}>
                    <p>{lote.bodegaName}</p>
                    <p className="list-row-secondary">{lote.clientName}</p>
                  </div>
                </div>
              ))
          ) : (
            <div className="no-lotes">
              <p>No se pudo encontrar ningun resultado</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddLotes;
