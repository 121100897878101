import Button from "./Button";
import ResumenRow from "./ResumenRow";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ResumenCard = ({
  selectedItems,
  today,
  disabled,
  onSelect,
  onSave,
  path,
  bodega,
  bodegaDestination,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-[569px] flex flex-col border rounded-lg shadow-lg p-4">
      <div className="grid gap-3 grid-rows-3">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold">Resumen</h1>
          <p className="text-[10px] font-semibold">{moment(today).format("DD MMMM YYYY")}</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-xs">
            <span className="font-semibold">Cliente: </span>
            {bodegaDestination?.client?.nombre || "-"}
          </p>
          <div className="flex justify-between items-center">
            <p className="text-xs">
              <span className="font-semibold">Ciudad: </span>
              {bodegaDestination?.city || "-"}
            </p>
            <div className="flex justify-between items-center">
              <p className="text-xs">
                <span className="font-semibold">Total items: </span>
              </p>
              <div className="w-4 h-4 flex justify-center items-center rounded-full bg-raisin-black ml-1">
                <p className="text-[9px] text-white">
                  {selectedItems.length}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-px bg-cadet-blue-crayola mt-1"></div>
      </div>
      <div className="h-full flex flex-col gap-5 overflow-y-scroll mb-6">
        {selectedItems.map((item, index) => (
          <ResumenRow
            key={index}
            item={item}
            onSelect={onSelect}
            onCheck={() => onSelect(item)}
          />
        ))}
      </div>
      <div className="flex justify-center gap-3">
        <Button
          variant="cancel"
          label="Cancelar"
          onClick={() => navigate(`/bodega/${path}`, { state: { bodega } })}
        />
        <Button
          variant="confirm"
          label="Guardar"
          disabled={disabled}
          onClick={() => onSave({ lotes: selectedItems })}
        />
      </div>
    </div>
  );
};

export default ResumenCard;
