import { url, axiosPrivate } from ".";

export const getIngresosApi = async () => {
  try {
    const ingresos = await axiosPrivate.get(`${url}/ingresos`);
    return ingresos.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const addIngresoApi = async (ingreso) => {
  try {
    const newBodega = await axiosPrivate.post(`${url}/ingresos`, {
      ...ingreso,
    });
    return newBodega.data.data;
  } catch (e) {
    console.log(e);
  }
};
