import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Button.css";
import Loading from "../Loading/Loading";

const Button = ({
  label,
  click,
  variant,
  size,
  isLoading,
  disabled,
  leftIcon,
  className,
}) => {
  return (
    <button
      onClick={click}
      className={`Button ${variant}-btn ${size}-btn ${className}`}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <span style={{ position: "relative" }}>
          <Loading />
        </span>
      ) : (
        <>
          {leftIcon && <FontAwesomeIcon icon={leftIcon} />} {label}
        </>
      )}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
};

Button.defaultProps = {
  variant: "cta",
  size: "medium",
};

export const ButtonProps = {
  variant: ["cta", "cancel", "action"],
};

export default Button;
