import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import HeaderBodegas from "../../components/ReactInventory/HeaderBodegas";
import ItemContainer from "../../components/ReactInventory/ItemContainer";
import BodegaRow from "../../components/ReactInventory/BodegaRow";
import { slugify } from "../../utils/core";
import Modal from "../../components/ReactInventory/Modal";
import { setCreateBodegaModal } from "../../store/appSlice";
import Loading from "../../components/Loading/Loading";
import { getBodegasApi } from "../../services/bodegas";

function Warehouses() {
  const clients = useSelector((state) => state.app.clients);
  const isModalOpen = useSelector((state) => state.app.isCreateBodegaModalOpen);
  const [bodegas, setBodegas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      getBodegasApi().then((bods) => {
        setIsLoading(false);
        setFilteredData(bods);
        setBodegas(bods);
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleFilter = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const filtered = bodegas.filter((element) =>
      Object.values(element).some((value) => {
        if (typeof value === "object") {
          return JSON.stringify(value).toLowerCase().includes(inputValue);
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(inputValue);
        }
        return false;
      })
    );
    setFilteredData(filtered);
    setSearchTerm(event.target.value);
  };

  return (
    <Page>
      <Container mainTop={false}>
        <HeaderBodegas
          handleFilter={handleFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          clientes={clients}
          clients={clients}
        />
        <div className="flex flex-col items-center">
          {isLoading ? (
            <Loading />
          ) : !!filteredData?.length ? (
            <ItemContainer>
              {filteredData
                .slice()
                .sort((a, b) =>
                  a.name === "Principal"
                    ? -1
                    : b.name === "Principal"
                    ? 1
                    : a.name.localeCompare(b.name)
                )
                .map((bodega, index) => (
                  <div key={index}>
                    <Link
                      to={`/bodegas/${slugify(bodega.name)}`}
                      state={{ bodega }}
                    >
                      <BodegaRow bodega={bodega} />
                    </Link>
                  </div>
                ))}
            </ItemContainer>
          ) : (
            <p className="text-center">No hay bodegas creadas</p>
          )}
        </div>
      </Container>
      {isModalOpen && (
        <Modal
          toggleModal={() => dispatch(setCreateBodegaModal(!isModalOpen))}
          clients={clients}
        />
      )}
    </Page>
  );
}

export default Warehouses;
