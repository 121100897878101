import AppRoutes from "./routes/AppRoutes";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import localization from "moment/locale/es";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// import "./App.css";

// import all FA icons and use them in the app like: <FontAwesomeIcon icon="plus-circle" or icon={["fa", "plus-circle"]} />
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);
library.add(...iconList);

function App() {
  const language = useSelector((state) => state.app.language);

  // load moment.js locale when language is selected
  useEffect(() => {
    // globally change moment (dates and time) locale
    moment.updateLocale(language === "Spanish" ? "es" : "en", localization);
  }, [language]);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
