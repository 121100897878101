import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import "./Checkbox.css";

const Checkbox = ({ variant, checkState, handleChange }) => {
  return (
    <div className={`Checkbox ${variant}`}>
      <FontAwesomeIcon
        icon={checkState ? faCheckSquare : faSquare}
        onClick={handleChange}
      />
    </div>
  );
};

export default Checkbox;
