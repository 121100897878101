import React from "react";
import { useSelector } from "react-redux";
// import { changeLanguage } from "../../store/appSlice";
// import { languagesOptions } from "../../content/elements/languagesOptions";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import appConfig from "../../../package.json";
import "./Settings.css";

const Settings = () => {
  // const language = useSelector((state) => state.app.language);
  const content = useSelector((state) => state.app.content);
  // const dispatch = useDispatch();

  return (
    <Page>
      <Container
        title={content.heading.SETTINGS_PAGE_TITLE}
        subtitle={content.heading.SETTINGS_PAGE_SUBTITLE}
      >
        <p>Version {appConfig.version}</p>
        {/* <div className="Settings">
          <label htmlFor="language">{content.texts.SELECT_LANGUAGE} </label>
          <select
            name="language"
            id="language"
            value={language}
            onChange={(e) => dispatch(changeLanguage(e.target.value))}
          >
            {languagesOptions.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </div> */}
      </Container>
    </Page>
  );
};

export default Settings;
