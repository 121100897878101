import { useEffect, useState } from "react";
import { MdSwapHoriz } from "react-icons/md";
import { useSelector } from "react-redux";
import DropdownSearch from "../DropdownSearch/DropdownSearch";

const HeaderDespacho = ({
  bodega,
  cliente,
  reverse,
  setReverse,
  selectedBodega,
  setSelectedBodega,
  onSwap,
}) => {
  const [parsedBodegas, setParsedBodegas] = useState({});
  const bodegas = useSelector((state) => state.app.bodegas);

  useEffect(() => {
    let parsedBodegas = bodegas
      .filter((bod) => bod._id !== bodega._id)
      .map((bod) => ({
        ...bod,
        cliente: bod.client.nombre,
      }));
    setParsedBodegas(parsedBodegas);
  }, [bodegas, bodega._id]);

  const handleSwap = () => {
    let updatedReverse = !reverse;
    setReverse(updatedReverse);
    let origin;
    let destination;
    if (reverse) {
      origin = bodega;
      destination = selectedBodega;
    } else {
      origin = selectedBodega;
      destination = bodega;
    }
    onSwap({ origin, destination });
  };

  return (
    <div className="grid grid-cols-3">
      <div
        className={`w-[428px] ${
          reverse ? "order-last justify-self-end" : "order-first"
        }`}
      >
        <p className="font-light text-xs">{reverse ? "Destino" : "Origen"}</p>
        <p className="font-semibold text-2xl">{bodega.name}</p>
        <p className="font-light text-sm">
          {cliente.nombre} - {cliente.nit}
        </p>
      </div>
      <div className="justify-self-center self-center">
        <MdSwapHoriz
          size={37}
          className="fill-raisin-black cursor-pointer"
          onClick={handleSwap}
        />
      </div>
      <div className={reverse ? "order-first" : "order-last justify-self-end"}>
        <p className="font-light text-xs mb-1.5">
          {reverse ? "Origen" : "Destino"}
        </p>
        <div className="w-[428px]">
          <DropdownSearch
            findField="name"
            findField2="cliente"
            placeholder="Bodega"
            suggestions={parsedBodegas}
            onSelect={setSelectedBodega}
            selectedItem={selectedBodega}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderDespacho;
