import React from "react";
import "./Status.css";

const Status = ({ status, label }) => {
  return (
    <div className="Status">
      <span className={status}></span>
      <p>{label}</p>
    </div>
  );
};

export default Status;
