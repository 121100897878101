import React from "react";
import "./InputText.css";

const InputText = ({
  label,
  value,
  maxLength,
  disabled,
  handleChange,
  isPassword,
  size,
  length,
  onFocus,
  onBlur,
}) => {
  return (
    <div className="InputText">
      <input
        type={`${isPassword ? "password" : "text"}`}
        name={label}
        maxLength={maxLength}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`${size} ${length}`}
      />
    </div>
  );
};

export default InputText;
