import { FaTimes } from "react-icons/fa";

const ResumenRow = ({ item, onSelect }) => {
  const handleClick = () => {
    onSelect(item);
  };

  return (
    <div className="flex">
      <div className="basis-[65%]">
        <p className="text-xs font-medium">
          {item.product.nombre} - {item.product.codigo}
        </p>
        <p className="text-[9px] font-light">{item.fecha_vencimiento}</p>
      </div>
      <p className="text-[9px] basis-[25%]">{item.lote}</p>
      <div className="flex basis-[10%] justify-end">
        <FaTimes
          size={12}
          className="fill-cool-grey cursor-pointer"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default ResumenRow;
