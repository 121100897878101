import React from "react";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import IngresosList from "../../components/Ingresos/IngresosList";
import IngresosHeader from "../../components/Ingresos/IngresosHeader";

const Ingresos = () => {
  return (
    <Page>
      <Container mainTop={false}>
        <IngresosHeader />
        <IngresosList />
      </Container>
    </Page>
  );
};

export default Ingresos;
