import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/Input/Input";
import { loadInitialData, login } from "../../store/appSlice";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import LogoNess from "../../components/Icons/Logo/LogoNess";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const content = useSelector((state) => state.app.content);
  const serverResponse = useSelector((state) => state.app.serverResponse);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signin = (e) => {
    setIsLoading(true);
    e.preventDefault();
    dispatch(login(email, password, content)).then(() => {
      if (JSON.parse(localStorage.getItem("isLogged"))) {
        dispatch(loadInitialData());
        navigate("/cirugias");
      }
      setIsLoading(false);
    });
  };

  return (
    <div className="Login">
      <div className="logo-wrapper">
        <LogoNess color="#1d1f31" size="medium" />
      </div>
      <h1 className="title-md">{content.labels.LOGIN}</h1>
      <div className="form">
        <Input
          value={email}
          type="text"
          label={content.labels.EMAIL}
          handleChange={(e) => setEmail(e.target.value)}
        />
        <Input
          value={password}
          type="text"
          label={content.labels.PASSWORD}
          isPassword
          handleChange={(e) => setPassword(e.target.value)}
        />
        <div className="button-wrapper">
          <Button
            click={signin}
            label={content.labels.LOGIN}
            isLoading={isLoading}
            className="h-9"
          />
        </div>
        <p>{serverResponse}</p>
      </div>
      <br />
      <div className="login-footer">
        <p>&copy; Ness Digital {new Date().getFullYear()}</p>
      </div>
    </div>
  );
};

export default Login;
